import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane,} from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import {productService} from "../../../_services/product.service";
import classnames from "classnames";
import Loader from "react-loader-spinner";
import ReactTextCollapse from "react-text-collapse";
import DataTable from 'react-data-table-component';
import SortIcon from "@material-ui/icons/ArrowDownward";

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: 14,
      justifyContent: 'center'
    },
  },
  cells: {
    style: {
      borderLeft: '1px solid #eee',
      justifyContent: 'center'
    },
  }, rows: {
    style: {
      borderRight: '1px solid #eee',
      justifyContent: 'center'
    }
  }
};

let toggle = false;
const TEXT_COLLAPSE_OPTIONS = {
  collapse: false, // default state when component rendered
  collapseText: "show more", // text to show when collapsed
  expandText: "show less", // text to show when expanded
  minHeight: 165, // component height when closed
  maxHeight: 750, // expanded to,
  textStyle: {
    // pass the css for the collapseText and expandText here
    color: "#fff",
    fontSize: "12px",
    fontWeight: "bold",
    backgroundColor: "#222",
    padding: 5,
    marginTop: 0,
    cursor: "pointer",
  },
};

class ProductTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      images: "",
      imagesChecked: false,
      imgList: [],
      imagesLoading: true,
      filesLoading: true,
      activeTab: "2",
      activeLang: "en",
      images: [],
      patientInfo: [],
      videos: [],
      product: {},
      files: [],
      links: [],
      Literaturelinks: [],
      filesChecked: false,
      modalImage: "",
      showModal: false,
      otherFileColumns: [
        {
          name: 'File',
          selector: 'size',
          sortable: true,
          cell: row => <img
            src="/assets/images/pdf.png"
            alt="Logo of Company"
            height="60"
            style={{padding: 10}}
            className="cursor"
            onClick={() => window.open(row.fileUrl, "_blank")}
          />
        },
        {
          name: "Description",
          selector: 'description',
          sortable: true,
        }
      ],
      columnss: [
        {
          name: 'Image',
          selector: 'size',
          sortable: true,
          cell: row => this.imagesFile(row)
        },
        {
          name: 'Description',
          selector: 'description',
          sortable: true,
        }
      ],
      navItems: [
        {
          id: "2",
          type: "literature",
          title: "Scientific",
          heading: "About Literature",
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.`,
        },
        // {
        //   id: "3",
        //   type: "intolerances",
        //   title: "Intolerances",
        //   heading: "Intolerances",
        //   description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
        //                         printer took a galley of type and scrambled it to make a type specimen book.`
        // },
        {
          id: "4",
          type: "technical",
          title: "Technical Information",
          heading: "About Technical Information",
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.`,
        },
        {
          id: "5",
          type: "effects",
          title: "Indications",
          heading: "Indications",
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.`,
        },
        // {
        //   id: "6",
        //   type: "problems",
        //   title: "Counter Indications",
        //   heading: "About Counter Indications",
        //   description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
        //                         printer took a galley of type and scrambled it to make a type specimen book.`
        // },
        {
          id: "7",
          type: "files",
          title: "Other Information",
          heading: "Files",
          files: [],
          description: ``,
        },
        {
          id: "8",
          type: "images",
          title: 'Images',
          heading: "Images",
          images: [
            {
              src: "/assets/images/img-icon.png",
            },
            {
              src: "/assets/images/img-icon.png",
            },
            {
              src: "/assets/images/img-icon.png",
            },
            {
              src: "/assets/images/img-icon.png",
            },
          ],
          description: ``,
        },
        {
          id: "9",
          type: "links",
          title: "Links",
          heading: "Links",
          links: [],
        },
        {
          id: "10",
          type: "videos",
          title: "Videos",
          heading: "Videos",
          description: ``,
        },
        {
          id: "1",
          type: "information",
          title: "Patient Information",
          heading: "About Information",
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.`,
        },
        {
          id: "11",
          type: "packs",
          title: "Packs",
          heading: "Packs",
          description: ``,
        },
      ],
      videoPlay: "",
      showVideoModal: false,
      packs: [],
      otherInfoFiles: []
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleProps() {
    this.setState({showModal: false});
  }

  imagesFile(row) {
    var filePath = row.baseUri;
    if (filePath !== null && filePath !== undefined) {
      if (filePath.includes(".png?") || filePath.includes(".jpg?") || filePath.includes(".jpeg?")) {
        return <img
          src={row.baseUri}
          alt="Logo of Company"
          height="60"
          style={{padding: 10}}
          className="cursor"
          onClick={() => window.open(row.baseUri, "_blank")}
        />;
      } else {
        return <img
          src="/assets/images/pdf.png"
          alt="Logo of Company"
          height="60"
          style={{padding: 10}}
          className="cursor"
          onClick={() => window.open(row.baseUri, "_blank")}
        />;
      }
    }
  }

  toggleImage(path) {
    this.setState({showModal: true, modalImage: path});
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  getProduct(id, langCode) {
    productService.getProductImagesLang(id, langCode).then((images) => {
      if (images) {
        if (typeof images == "object") {
          this.setState({
            images: images,
            imagesLoading: false,
            imagesChecked: true,
          });
        }
      }
    });

    productService.getProductFilesLang(id, langCode).then((files) => {
      if (files) {
        if (typeof files == "object") {
          this.setState({
            files: files,
            filesLoading: false,
            filesChecked: true,
          });
        }
      }
    });

    productService.getProductVideosLang(id, langCode).then((files) => {
      if (files) {
        if (typeof files == "object") {
          this.setState({videos: files});
        }
      }
    });

    productService.getProductLinksLang(id, langCode).then((links) => {
      if (links) {
        if (typeof links == "object") {
          this.setState({links: links});
        }
      }
    });

    productService.getProductLiteratureLinksLang(id, langCode).then((links) => {
      if (links) {
        if (typeof links == "object") {
          this.setState({Literaturelinks: links});
        }
      }
    });

    productService.getProductPacksLang(id, langCode).then((packs) => {
      this.setState({packs: packs});
    });

    productService.productPatInformationLocationLang(id, langCode).then((patientInfo) => {
      if (patientInfo) {
        if (typeof patientInfo == "object") {
          this.setState({
            patientInfo: patientInfo
          });
        }
      }
    });

    productService.getProductInfoFileLang(id, langCode).then((otherInfoFiles) => {
      console.log("otherInfoFiles::::", otherInfoFiles);
      if (otherInfoFiles) {
        if (typeof otherInfoFiles == "object") {

          this.setState({
            otherInfoFiles: otherInfoFiles
          });
        }
      }
    });
  }


  setProduct(pdt) {
    if (this.state.product.id === undefined) this.setState({product: pdt});
  }

  setVideoPlay(videoLink) {
    this.setState({showVideoModal: true});
    var video = (
      <video width="100%" height="400" controls>
        <source src={videoLink} type="video/mp4"/>
      </video>
    );
    this.setState({videoPlay: video});
  }

  toggleLang = lang => {
    if (this.state.activeLang !== lang) {
      this.setState({
        activeLang: lang
      });
      this.props.toggleDetails(lang);
      this.getProduct(this.state.product.id, lang);
      this.getTranslation(lang);
    }
  };

  getTranslation = async (langCode) => {
    //const filePath = `././././language-${langCode}.js`
    const filePath = `./language-${langCode}.js`
    const file = await require(`${filePath}`)
    this.setState({
      navItems: [
        {
          id: "2",
          type: "literature",
          title: file.Literature,
          heading: "About Literature",
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.`,
        },
        {
          id: "4",
          type: "technical",
          title: file.TechnicalInformation,
          heading: "About Technical Information",
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.`,
        },
        {
          id: "5",
          type: "effects",
          title: file.Indications,
          heading: "Indications",
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.`,
        },
        {
          id: "7",
          type: "files",
          title: file.OtherInformation,
          heading: "Files",
          files: [],
          description: ``,
        },
        {
          id: "8",
          type: "images",
          title: 'Images',
          heading: 'Images',
          images: [
            {
              src: "/assets/images/img-icon.png",
            },
            {
              src: "/assets/images/img-icon.png",
            },
            {
              src: "/assets/images/img-icon.png",
            },
            {
              src: "/assets/images/img-icon.png",
            },
          ],
          description: ``,
        },
        {
          id: "9",
          type: "links",
          title: file.links,
          heading: "Links",
          links: [],
        },
        {
          id: "10",
          type: "videos",
          title: file.videos,
          heading: "Videos",
          description: ``,
        },
        {
          id: "1",
          type: "information",
          title: file.PatientInformation,
          heading: "About Information",
          description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.`,
        },
        {
          id: "11",
          type: "packs",
          title: file.Packs,
          heading: "Packs",
          description: ``,
        },
      ]
    });
  }

  render() {
    const {product} = this.state;
    if (
      this.props.product.id &&
      this.state.activeLang &&
      this.state.imagesLoading === true &&
      this.state.imagesChecked === false
    ) {
      this.setProduct(this.props.product);
      this.getProduct(this.props.product.id, this.state.activeLang);
    }

    if (
      this.props.productNew !== undefined &&
      this.state.activeLang &&
      this.state.imagesChecked === false
    ) {
      if (this.props.productNew.id !== undefined) {
        this.getProduct(this.props.productNew.id, this.state.activeLang);
        this.setProduct(this.props.productNew);
      }
    }

    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({active: this.state.activeLang === "en"})}
              onClick={() => {
                this.toggleLang("en");

              }}
            >
              English
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: this.state.activeLang === "de"})}
              onClick={() => {
                this.toggleLang("de");

              }}
            >
              Deutsch
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: this.state.activeLang === "fr"})}
              onClick={() => {
                this.toggleLang("fr");

              }}
            >
              Français
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: this.state.activeLang === "it"})}
              onClick={() => {
                this.toggleLang("it");

              }}
            >
              Italiano
            </NavLink>
          </NavItem>
        </Nav>
        <Nav tabs>
          {this.state.navItems.map((navItem) => (
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === navItem.id,
                })}
                onClick={() => {
                  this.toggle(navItem.id);
                }}
              >
                {navItem.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          {this.state.navItems.map((navItem) => {
            let content = "";

            if (navItem.type == "files" && this.state.filesLoading === true) {
              content = (
                <center>
                  <Loader type="ThreeDots" color="#00BFFF"></Loader>
                </center>
              );
            } else if (
              navItem.type == "images" &&
              this.state.imagesLoading === true
            ) {
              content = (
                <center>
                  <Loader type="ThreeDots" color="#00BFFF"></Loader>
                </center>
              );
            } else if (
              navItem.type == "literature" &&
              this.state.filesLoading === false
            ) {
              content = (
                <div className="col-md-12 d-flex">
                  <div className="row">
                    <div className="col-12">
                      {this.state.files.map((files) => (
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          {" "}
                          <img
                            src="/assets/images/pdf.png"
                            alt="Logo of Company"
                            height={120}
                            className="cursor"
                            onClick={() => window.open(files.baseUri, "_blank")}
                          />{" "}
                          <p>{files.description}</p>
                        </div>
                      ))}
                    </div>
                    <div className="col-12">
                      <div className="row">
                        {this.state.Literaturelinks.map((link) => (
                          <div className="col-md-12 col-sm-12">
                            {" "}
                            <a href={link.url} target="_blank">
                              <strong>{link.url}</strong>
                            </a>
                            <p>{link.description}</p>
                            <hr/>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>


                </div>

              );
            } else if (
              navItem.type == "images" &&
              this.state.imagesLoading === false
            ) {
              content = (
                <div className="row">
                  {this.state.images.map((images) => (
                    <div className="col-md-6 col-sm-12">
                      <div className="row">
                        <div className="col-md-5 col-sm-12">
                          {" "}
                          <img
                            src={images.baseUri}
                            alt="Logo of Company"
                            width="100%"
                            onClick={() => {
                              this.toggleImage(images.baseUri);
                            }}
                          />{" "}
                        </div>
                        <div className="col-md-7 col-sm-12 lalign">
                          {images.description}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            } else if (navItem.type === "information") {
              content = (
                <div>
                  <DataTable
                    columns={this.state.columnss}
                    data={this.state.patientInfo}
                    customStyles={customStyles}
                    selectableRowsComponentProps={{inkDisabled: true}}
                    pagination={false}
                    striped={true}
                    noHeader
                    highlightOnHover
                    pointerOnHover
                    dense
                    style={{
                      "borderWidth": "1px",
                      'borderColor': "#D3D3D3",
                      'borderStyle': 'solid',
                      "margin": "0px auto",
                      "text-align": "center",
                      "text-Align": "center"
                    }}
                    sortIcon={<SortIcon/>}
                  />

                  <p className="wordbreak">
                    <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                      {ReactHtmlParser(product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].literature : product.literature)}
                    </ReactTextCollapse>{" "}
                  </p>

                </div>
              );
            } else if (navItem.type == "files") {
              content = <div className="row">
                <div className="col-12">
                  <p>{ReactHtmlParser(product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].literature : product.literature + "<style>table tr td{border:1px solid #999 !important;padding:10px;}</style>")}</p>
                </div>
                <div className="col-12">
                  <DataTable
                    columns={this.state.otherFileColumns}
                    data={this.state.otherInfoFiles}
                    customStyles={customStyles}
                    selectableRowsComponentProps={{inkDisabled: true}}
                    pagination={false}
                    striped={true}
                    noHeader
                    highlightOnHover
                    pointerOnHover
                    dense
                    style={{
                      "borderWidth": "1px",
                      'borderColor': "#D3D3D3",
                      'borderStyle': 'solid',
                      "margin": "0px auto",
                      "text-align": "center",
                      "text-Align": "center"
                    }}
                    sortIcon={<SortIcon/>}
                  />
                </div>
              </div>;
            } else if (navItem.type == "intolerances") {
              content = <p>{product.intolerances}</p>;
            } else if (navItem.type == "technical") {
              content = (
                <p>
                  <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                    {ReactHtmlParser(product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].technicalInformation : product.technicalInformation)}
                  </ReactTextCollapse>
                </p>
              );
            } else if (navItem.type === "problems") {
              content = <p>{product.counterIndications}</p>;
            } else if (navItem.type === "effects") {
              content =
                <p>{product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].indications : product.indications}</p>;
            } else if (navItem.type === "packs") {
              content = (
                <div className="row">
                  {this.state.packs.map((pack) => (
                    <div className="col-md-12 col-sm-12">
                      {" "}
                      <p>
                        {pack.size} {pack.formulation} ,{" "}
                        <strong>{pack.price}</strong>
                      </p>
                      <hr/>
                    </div>
                  ))}
                </div>
              );
            } else if (navItem.type === "links") {
              content = (
                <div className="row">
                  {this.state.links.map((link) => (
                    <div className="col-md-12 col-sm-12">
                      {" "}
                      <a href={link.url} target="_blank">
                        <strong>{link.url}</strong>
                      </a>
                      <p>{link.description}</p>
                      <hr/>
                    </div>
                  ))}
                </div>
              );
            } else if (navItem.type == "videos") {
              content = (
                <>
                  {this.state.videos.map((video) => (
                    <div className="row">
                      <div className="col-md-2 col-sm-12">
                        {" "}
                        <img
                          src="/assets/images/video-icon.png"
                          alt="Logo of Company"
                          height={120}
                          onClick={() => {
                            this.setVideoPlay(video.baseUri);
                          }}
                          style={{cursor: "pointer"}}
                        />{" "}
                      </div>
                      <div className="col-md-10 col-sm-12">
                        <p>{video.description}</p>
                      </div>
                    </div>
                  ))}
                </>
              );
            }

            return (
              <TabPane tabId={navItem.id}>
                {/* <h3>{navItem.heading}</h3> */}
                {content}
              </TabPane>
            );
          })}
        </TabContent>
        <Modal isOpen={this.state.showModal} className="modal-content modal-lg">
          <ModalHeader toggle={() => this.toggleProps()}></ModalHeader>
          <ModalBody>
            <img src={this.state.modalImage} style={{width: "100%"}}/>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showVideoModal}
          className="modal-content modal-lg"
        >
          <ModalHeader
            toggle={() => this.setState({showVideoModal: false})}
          ></ModalHeader>
          <ModalBody>{this.state.videoPlay}</ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  product: state.product.product,
  // product: state.product.id
});

export default connect(mapStateToProps)(ProductTabs);
