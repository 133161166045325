import "./App.scss";

import React, {Component} from "react";
import {Redirect, Route, BrowserRouter as Router, Switch} from "react-router-dom";

import Article from "./website/dashboard/article/Article";
import Chatroom from "./website/dashboard/chatroom.jsx";
import ContainingResult from "./website/dashboard/search/containing-results";
import DocRegister from "./views/Public/Login/docregister.jsx";
import DocVerify from "./views/Public/Login/verify.jsx";
import GPT from "./website/dashboard/gpt";
import {GlobalHistory} from "./_helpers/history";
import Imprint from "./website/imprint";
import Messages from "./website/dashboard/messages";
import Privacy from "./website/privacy";
import PrivateRoute from "./PrivateRoute";
import ProductDetails from "./website/dashboard/product-details/ProductDetails";
import Profile from "./website/dashboard/profile";
import Search from "./website/dashboard/search"
import {Spinner} from "reactstrap";
import Success from "./views/Public/Login/Success";
import Support from "./website/dashboard/support";
import searchDisplay from "./website/dashboard/search/searchDisplay";
import {userService} from "./_services/user.service";

const loading = () => <Spinner color="success"/>;
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
// const LandingPage = React.lazy(() => import('./views/Public/Landing/LandingPage'));
const Confirmation = React.lazy(() =>
  import("./views/Public/Register/Confirmation")
);
const SearchResult = React.lazy(() =>
  import("./views/Public/Search/SearchResult/SearchResult")
);
const Login = React.lazy(() => import("./views/Public/Login"));
const Maintenance = React.lazy(() => import("./views/Public/Login/Maintenance"));
const Fgt = React.lazy(() => import("./views/Public/Login/Fgt"));
const Reset = React.lazy(() => import("./views/Public/Login/Reset"));

const Logout = React.lazy(() => import("./views/Public/Login/Logout"));
const Register = React.lazy(() => import("./views/Public/Register/Register"));
const Page404 = React.lazy(() => import("./views/Public/Page404"));
const Page500 = React.lazy(() => import("./views/Public/Page500"));

// const Login = React.lazy(() => import('./views/Public/authentication/holder'));

class App extends Component {
  render() {
    return (
      <Router>
        <React.Suspense fallback={loading()}>
          <GlobalHistory/>
          <Switch>
            {/*<Route
              exact
              path="/"
              name="Maintenance"
              render={props => <Maintenance {...props} />}
            />
            <Redirect to="/" />
            */}
            <Route
              exact
              path="/login"
              render={props =>
                userService.isLoggedIn() ? (
                  <Redirect to="/"/>
                ) : (
                  <Login {...props} />
                )
              }
            />
            <Route
              exact
              path="/logout"
              name="Logout Page"
              render={props => <Logout {...props} />}
            />
            <Route
              exact
              path="/forgottenpassword"
              name="Forgotten Password"
              render={props => <Fgt {...props} />}
            />

            <Route
              exact
              path="/resetpassword"
              name="Reset Password"
              render={props => <Reset {...props} />}
            />


            <Route
              exact
              path="/confirmation"
              render={props => <Confirmation {...props} />}
            />
            <Route
              exact
              path="/search-result"
              name="Search Result Page"
              render={props => <SearchResult {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={props => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={props => <Page500 {...props} />}
            />
            <Route
              exact
              path="/dashboard/product/details"
              name="productDetails"
              render={props => <ProductDetails {...props} />}
            />
            <PrivateRoute
              path="/dashboard"
              name="Home"
              component={DefaultLayout}
            />
            <Route exact path="/profile" name="profile" component={Profile}/>
            <Route exact path="/chatroom" name="chatroom" component={Chatroom}/>
            <Route exact path="/messages" name="messages" component={Messages}/>
            <Route exact path="/support" name="support" component={Support}/>
            <Route exact path="/search" name="search" component={Search}/>
            <Route exact path="/privacy" name="privacy" component={Privacy}/>
            <Route exact path="/imprint" name="imprint" component={Imprint}/>
            <Route exact path="/gpt" name="gpt" component={GPT}/>
            <Route
              exact
              path="/resultLoader"
              name="resultLoader"
              render={props => <ContainingResult {...props} />}
            />
            <Route
              exact
              path="/docregister"
              name="docregister"
              component={DocRegister}
            />
            <Route
              exact
              path="/maintenance"
              name="maintenance"
              component={Maintenance}
            />
            <Route
              exact
              path="/verify/:token"
              name="verify"
              component={DocVerify}
            />
            <Route
              exact
              path="/searchDisplay"
              name="searchDisplay"
              component={searchDisplay}
            />
            <Route
              exact
              path="/readArticle"
              name="readArticle"
              component={Article}
            />
            <Route
              exact
              path="/Success"
              name="Success"
              component={Success}
            />
            <Redirect to="/dashboard"/>

            {/*<Route exact path="/" name="Landing Page" render={props => <LandingPage {...props}/>} /> */}
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
