import axios from "axios";
import {API} from "../urls";

export const chatroomService = {
  createGroup,
  getGroup,
  inviteToGroup,
  acceptDeclineGroup,
  updateGroup,
  updateChatGroupForAlerting,
  createGroupAccess,
  getLastGroupAccess,
  getLastGroupAccessForAll,
  getLastDoctorAccessForAll,
  pushNotification,

}

function createGroup(data) {
  let url = API.chatGroupLocation.toUrl();
  return axios.post(url, data);
}

function getGroup() {
  let url = API.chatGroupLocation.toUrl();
  return axios.get(url);
}

function inviteToGroup(data) {
  let url = API.chatGroupInvitingLocation.toUrl();
  return axios.post(url, data);
}

function acceptDeclineGroup(id, option) {
  let url = API.chatGroupInviteLocation.toUrl({id: id, option: option});
  return axios.put(url);
}

function updateGroup(id, data) {
  let url = API.chatGroupUpdateLocation.toUrl({id: id});
  return axios.put(url, data);
}

// the date and time will be added at the backend
function updateChatGroupForAlerting(groupId, lastSender) {
  let url = API.updateChatGroupForAlertingLocation.toUrl({groupId: groupId, lastSender: lastSender});
  return axios.put(url);
}

//this function is used in updating  the message alert to create an input for each user in each group
function createGroupAccess(groupId, userId) {
  let url = API.createGroupAccessLocation.toUrl({groupId: groupId, userId: userId});
  return axios.post(url);
}


//this function is used in updating  the message alert to create an input for each user in each group
function getLastGroupAccess(groupId, userId) {
  let url = API.getLastGroupAccessLocation.toUrl({groupId: groupId, userId: userId});
  return axios.get(url);
}

function getLastUserAccess(userId) {
  let url = API.getLastUserAccessLocation.toUrl({userId: userId});
  return axios.get(url);
}


function getLastGroupAccessForAll() {
  let url = API.getLastGroupAccessForAllLocation.toUrl();
  return axios.get(url);
}

function getLastDoctorAccessForAll() {
  let url = API.getLastDoctorAccessForAllLocation.toUrl();
  return axios.get(url);
}

function pushNotification(message, userId, groupId) {
  let url = API.pushNotificationLocation.toUrl({message: message, userId: userId, groupId: groupId});
  return axios.post(url);
}



