import i18n from "i18next";
import {initReactI18next} from 'react-i18next';
import common_en from './translations/en.json';
import common_de from './translations/de.json';
import common_fr from './translations/fr.json';
import common_it from './translations/it.json';


const resources = {
  en: {
      translation : common_en
  },

  de: {
      translation : common_de
  },

  fr: {
    translation : common_fr
},
  it: {
  translation : common_it
}
}

i18n
.use(initReactI18next)
.init({
  resources,
  lng: 'en',
  keySeperator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
