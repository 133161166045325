import cogoToast from "cogo-toast";

export const positions = {
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  TOP_RIGHT: "top-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
  BOTTOM_RIGHT: "bottom-right"
};

function onClick(hide) {
  hide();
}

function success(message, options) {
  cogoToast.success(message, { ...options, onClick: onClick });
}

function info(message, options) {
  cogoToast.info(message, { ...options, onClick: onClick });
}

function loading(message, options) {
  cogoToast.loading(message, { ...options, onClick: onClick });
}

function warn(message, options) {
  cogoToast.warn(message, { ...options, onClick: onClick });
}

function error(message, options) {
  cogoToast.error(message, { ...options, onClick: onClick });
}

export const cogoToastHelper = {
  success,
  info,
  loading,
  warn,
  error
};
