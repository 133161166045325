import React from "react";
import "./docregister.css";
import {Button} from "reactstrap";
import axios from "axios";
import {apiBaseUrl} from "../../../config.example";
import {LoginLocation} from "../../../locations";
import {history} from "../../../_helpers";
import i18next from "i18next";

export default class DocRegister extends React.Component {
  constructor(props) {
    super(props);
    console.log("The Props Of Registeration", props);

    this.state = {
      email: "thomas@arssoso.de",
      firstName: "Thomas",
      lastName: "Davis",
      password: "Timmyses@024",
      userName: "thomas.davis1",
      confirmPassword: "Timmyses@024",
      errorMessage: "",
      profilePicture: "",
      specialistTitle: "Dentist",
      academicTitle: "MD",
      employer: "Trafaga",
      message: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    var token = this.props.match.params.token;
    // token=token.replace("?search=")
    var config = {
      method: 'get',
      url: apiBaseUrl + '/Doctor/activate/' + token,
      headers: {"Content-Type": "application/problem+json"}
    };

    let self = this;
    axios(config)
      .then(function (response) {
        console.log("===>", response);
        self.setState({message: response});

        if (response === 'Account was successfully verified, kindly login now') {
          setTimeout(function () {
            history.push(LoginLocation.path);
          }, 5000);
        } else {
          history.push(LoginLocation.path);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    return (
      <div
        className="app  align-items-center jumbotron d-flex align-items-center"
        style={{
          backgroundImage: "url(/assets/img/doctors2.jpg)",
          backgroundSize: "cover"
        }}
      >
        <div class="body1 container register">
          <div class="row">
            <div class="col-md-3 register-left">
              <img src="/assets/img/logo1.png" alt=""/>
              <h3>Welcome</h3>
              <p>
                You are 30 seconds away from being a part of Digital Doctor
                House!
              </p>
              <Button
                style={{backgroundColor: "#2d2762", color: '#fff'}}
                onClick={event => (window.location.href = "./login")}
              >
                {i18next.t("backtoLogin")}
              </Button>
              <br/>
            </div>
            <div className="col-md-9 register-right">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <h3 className="register-heading">Account Verification</h3>
                  <form
                    className="row register-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="col-12" style={{textAlign: 'center'}}>
                      {(this.state.message !== "") &&
                        <strong>{this.state.message}<br/><br/></strong>
                      }
                      <p>You account was registered successfully , click on the button below to activate you profile.
                        When its done you will be taken to the login page. Thank you</p>
                      <Button
                        type="submit"
                        style={{backgroundColor: "#2d2762", color: '#fff'}}
                      >
                        Click here to activation your account
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
