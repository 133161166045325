import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { alert } from "./alert.reducer";
import { registration } from "./registration.reducer";

import searchReducer from "./searchReducer";
import articleReducer from "./articleReducer";

const rootReducer = combineReducers({
  authentication,
  alert,
  registration,

  product: searchReducer,
  article: articleReducer
});

export default rootReducer;
