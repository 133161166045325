import { BiCalendar, BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import React, { Component } from "react";
import { fetchProducts, filterProducts, saveKeyword, searchProduct, setLoading, } from "../../../_actions/search.action";
import Calendar from "react-awesome-calendar";
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { connect } from "react-redux";
import { eventService } from "../../../_services/event.service";
import { history } from "../../../../src/_helpers/history";
import i18next from "i18next";
import makeAnimated from 'react-select/lib/animated';
import { resultLoader } from "../../../../src/locations";
import { sourcesService } from "../../../_services/sources.service";
import EventsCardsList from "./EventCardsList";

const pageNumbers = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const currentTime = new Date();
const currentYear = currentTime.getFullYear();
const currentMonth = currentTime.getMonth();

const eventColorLabels = ['#F2A7C3', '#2C6CBF', '#88C9F2', '#6FBFB1', '#F2CD5C'];
const specialistTitle = (localStorage.getItem("specialistTitle") != undefined || localStorage.getItem("specialistTitle") != null ? localStorage.getItem("specialistTitle").split(',') : []);

function EventDetailsModal(props) {

  let event = props.event;
  let dateStart = new Date(event.startDate);
  // let startMonth = dateStart.toLocaleString('default', { startMonth: 'long' });
  let dateString = (((dateStart.getDate()) > 9 ? (dateStart.getDate()) : "0" + (dateStart.getDate())) + "." + ((dateStart.getMonth() + 1) > 9 ? (dateStart.getMonth() + 1) : "0" + (dateStart.getMonth() + 1)) + "." + dateStart.getFullYear());
  let startTime = ("0" + dateStart.getHours()).slice(-2) + ":" + ("0" + dateStart.getMinutes()).slice(-2);

  let dateEnd = new Date(event.endDate);
  let endTime = ("0" + dateEnd.getHours()).slice(-2) + ":" + ("0" + dateEnd.getMinutes()).slice(-2);

  const toggle = (e) => {
    props.toggle(e)
  }

  return (<>
    <Modal backdrop={false} centered isOpen={props.isOpen} toggle={toggle} className="border-0">
      <ModalHeader toggle={toggle} className="border-0 ml-3 mt-0 mb-0">
        <span className="">{event.titleShort}</span>
      </ModalHeader>
      <ModalBody className="ml-3 mt-0 pt-0">
        <span className="modal-date pt-0 pb-0">{dateString}
          <p className="modal-time">{i18next.t("Start")}: {startTime}</p>
          <p className="d-inline-block ml-2">{i18next.t("End")}: {endTime}</p>
        </span>
        <span className="d-block mb-0">{event.description}</span>
        <label for="url" class="mb-0 pt-3 font-weight-bold">{i18next.t("registerHere")}</label>
        <span name="url" className="modal-url mb-0" onClick={() => {
          window.open(event.registrationLink)
        }}>{event.registrationLink}</span>
        <label for="location" class="mb-0 pt-3 font-weight-bold">{i18next.t("Location")}</label>
        <span name="url" className="modal-url mb-0">{event.location}</span>
      </ModalBody>
    </Modal>
  </>)
}
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Internal: false,
      External: false,
      Calendar: false,
      CalendarList: false,
      status: false,
      filterLocation: [],
      selectedFilterLocation: [],
      currentEvents: [],
      showEventFilter: [true, true, true, true, true, true],
      showModal: false,
      selectedEvent: {},
      searchedEvents: false,
      searchedType: false,
      filteredEvents: [],
      specialistTitle: [],
      selectedSpecialistTitle: [],
      professionalAreaList: [],
      startDate: "",
      endDate: "",
      currentCalendarDate: currentTime,
    };
    this.calendar = React.createRef();
    this.toggleModal = this.toggleModal.bind(this);
    this.handleCalendarChanges = this.handleCalendarChanges.bind(this);
    this.handleAreasChange = this.handleAreasChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.filterByLocation = this.filterByLocation.bind(this);
  }

  componentWillMount() {
    var filter = this.props.filter;

    if (typeof filter === "object" && this.state.status === false) {
      this.props.filterProducts("External");
      this.setState({ Internal: false, External: true, status: true });
    } else {
      if (this.state.status === false) {
        if (filter === "Internal")
          this.setState({ Internal: true, External: false, status: true });
        else this.setState({ Internal: false, External: true, status: true });
      }
    }

    if (localStorage.getItem("searchType") !== null) {
      if (localStorage.getItem("searchType") === "External") {
        this.props.filterProducts("External");
        this.setState({ Internal: false, External: true, status: true });
      } else if (localStorage.getItem("searchType") === "Internal") {
        this.props.filterProducts("Internal");
        this.setState({ Internal: true, External: false, status: true });
      }
    }

    const searchType = this.props.searchType ? this.props.searchType : null;
    if (!searchType || searchType === 'External') this.onClickFilter({ target: { value: 'External' } });
    if (searchType === 'Internal') this.onClickFilter({ target: { value: 'Internal' } });
    if (searchType === 'Calendar') this.onClickCalendar({ target: { value: 'Calendar' } });

  }

  handleInputChange = (e) => {
    e.preventDefault();

    // check if the input is empty
    if (e.target.value === "") {
      this.setState({
        searchedEvents: false,
        searchedType: false,
      });
    }

    // This is responsible for the textchange in the searchbar
    this.props.searchProduct(e.target.value);

    this.setState({ text: e.target.value });
    localStorage.setItem("searchData", e.target.value);

  };

  handleKeyUp = (e) => {
    if (e.key === "Enter") {
      this.onClick(e);
    }
  };

  onClick = (e) => {
    if (this.textInput.value === "") {
      this.setState({
        searchedEvents: false,
        searchedType: false,
      });
      return;
    }

    localStorage.setItem("searchData",  this.textInput.value);

    if (this.state.Calendar === false && this.state.CalendarList === false) {
      if (this.props.text !== "") {
        history.push(resultLoader.toUrl({ id: this.props.text }));
      } else {
        e.preventDefault();
      }
    } else {
      this.handleEventSearch(localStorage.getItem("searchData"));
      e.preventDefault();
    }

  };

  onClickFilter = (e) => {
    this.props.filterProducts(e.target.value);
    localStorage.setItem("searchType", e.target.value);
    if (e.target.value === "Internal")
      this.setState({
        Internal: true,
        External: false,
        status: true,
        Calendar: false,
        CalendarList: false,
      });
    else this.setState({
        Internal: false,
        External: true,
        status: true,
        Calendar: false,
        CalendarList: false,
      });
  };

  /**** Calendar Section ****/
  onClickCalendar = (e) => {
    this.setState({
      Internal: false,
      External: false,
      Calendar: true,
      CalendarList: false,
      status: true,
    });
    this.getEvents(currentYear, currentMonth);
    localStorage.setItem("searchType", e.target.value);
    this.props.filterProducts("Calendar");
    this.getProfessionalArea();
  };

  onClickCalendarList = async (e) => {
    this.setState({
      Internal: false,
      External: false,
      Calendar: false,
      CalendarList: true,
      status: true,
    });
    this.getAllEvents();
  };

  formatterEvents = (events) => {
    const tempEvents = [];
    let event = {};
    let colorArrayIndex = 0;

    if (events.length > 0)
      events.forEach(element => {
        let dateFrom = new Date(element.startDate);
        dateFrom.setHours(dateFrom.getHours() + 2);

        event = {
          id: element.id,
          color: eventColorLabels[colorArrayIndex],
          from: dateFrom,
          to: element.endDate,
          title: element.title + " - " + (element.description != null ? element.description : ""),
          registrationLink: element.registrationLink,
          location: element.location,
          checked: true,
          startDate: element.startDate,
          endDate: element.endDate,
          description: element.description,
          titleShort: element.title
        }
        tempEvents.push(event);

        if (++colorArrayIndex > 4) {
          colorArrayIndex = 0;
        }

      });
    return tempEvents;
  }

  getEvents = (year, month) => {
    eventService.getEvents(year, month).then((eventList) => {
      const formattedEvents = this.formatterEvents(eventList);
      this.setState({
        filterLocation: this.setfilterElements(formattedEvents),
        currentEvents: formattedEvents,
        filteredEvents: formattedEvents
      })
    });
  }

  getAllEvents = () => {
    eventService.getAllEvents().then((eventList) => {
      const formattedEvents = this.formatterEvents(eventList);
      // order events by date ascending
      formattedEvents.sort((a, b) => {
        // Add the 2000 years to the dates that are under 2000
        a.startDate = this.addMissingMilleniums(new Date(a.startDate));
        b.endDate = this.addMissingMilleniums(new Date(b.endDateDate));

        return new Date(a.startDate) - new Date(b.startDate);
      });

      this.setState({
        currentEvents: formattedEvents,
      });
    });
  }

  addMissingMilleniums = (date) => {
    if (date.getFullYear() < 2000) {
      date.setFullYear(date.getFullYear() + 2000);
    }
    return date;
  }

  getEventData = (year, month) => {
    eventService.getEvents(year, month).then((eventList) => {
      let formattedEvents = this.formatterEvents(eventList);
      if (this.state.filteredEvents.length !== 0) {
        formattedEvents = this.state.filteredEvents;
      }
      let filterResults = [];
      let startDate = (this.state.startDate !== "" ? new Date(this.state.startDate) : "")
      let endDate = (this.state.endDate !== "" ? new Date(this.state.endDate) : "")

      // After loading the data
      // Is each type of filter checked for its state
      // The results of the filtering are stored in <filterResults>
      if (this.state.startDate !== "") {
        let month = startDate.getMonth();
        if (startDate.getMonth() <= month || startDate.getMonth() == month) {
          filterResults = formattedEvents.filter(e => e.startDate >= this.state.startDate);
        } else {
          filterResults = formattedEvents;
        }
      }
      if (this.state.endDate != "") {
        if (endDate.getMonth() <= month || endDate.getMonth() == month) {
          filterResults = formattedEvents.filter(e => e.endDate < this.state.endDate);
        } else {
          filterResults = formattedEvents;
        }
      }
      // This case occurs when the time filters are not set.
      if ((this.state.startDate === "") && (this.state.endDate === "")) {
        filterResults = (formattedEvents);
      }

      if (this.state.selectedSpecialistTitle.length > 0) {
        const areas = [...this.state.selectedSpecialistTitle];
        if (this.state.selectedSpecialistTitle.length > 1) {
          let results = []
          areas.forEach(a => {
            let array = this.filter(a.label, filterResults);
            Array.prototype.push.apply(results, array);
          });
          if (results.length > 0) {
            filterResults = results;
          } else {
            filterResults = [];
          }
        } else {
          filterResults = this.filter(areas[0].label, filterResults);
          filterResults = this.filterByLocation(filterResults, this.state.selectedFilterLocation);
        }
      }

      if (this.state.searchedEvents === true) {
        let searchArray = this.filter(localStorage.getItem("searchData"), formattedEvents);
        searchArray = this.filterByLocation(searchArray, this.state.selectedFilterLocation);
        this.setState({
          currentEvents: formattedEvents,
          filteredEvents: searchArray
        })
      } else {
        // Check if Filtertype selected
        // When no type is selected
        // This case occurs when only the date filter is selected.
        // if ((this.state.searchedEvents === false) && (this.state.selectedSpecialistTitle.length === 0)) {
        //   filterResults = (formattedEvents);
        // }

        this.setState({
          filterLocation: this.setfilterElements(filterResults),
          currentEvents: formattedEvents,
          filteredEvents: filterResults
        })
      }
    });
  }

  getProfessionalArea() {
    sourcesService.professionalArea().then(
      (response) => {
        this.setState({ professionalArea: response });

        var dataList = response.map(area => {
          let option = {
            value: area.id,
            label: area.area,
          }
          return option;
        });
        this.setState({ professionalAreaList: dataList });

      }, (error) => {
        console.error(error);
      }
    );
  }

  getSearchEventsByArea(id) {
    let getArea = this.state.professionalAreaList.find(p => p.value == id);
    let results = this.filter(getArea.label, this.state.currentEvents);
    return results;
  }

  getSearchEventsDate(name, date) {
    const events = ((this.state.selectedSpecialistTitle.length > 0) && this.state.filteredEvents.length > 0 ? [...this.state.filteredEvents] : [...this.state.currentEvents])

    let filteredDateResults = [];
    if (name === "endDate") {
      filteredDateResults = events.filter(item => item.endDate <= date)
      this.setState({
        filteredEvents: filteredDateResults,
        filterLocation: this.setfilterElements(filteredDateResults),
      })

    } else {
      filteredDateResults = events.filter(item => item.startDate >= date)
      this.setState({
        filteredEvents: filteredDateResults,
        filterLocation: this.setfilterElements(filteredDateResults),
      })
    }

  }

  setfilterElements(events) {
    const copyEvents = events;
    const location = [];

    copyEvents.forEach(item => {
      if ((item.location != null && item.location.length != 0) && (!location.some((e) => e.name === item.location))) {
        let element = {
          label: item.location,
          value: item.location
        }
        location.push(element);
      }
    });
    return location;
  }

  filterByLocation(events, locations) {
    if (locations.length > 0) {
      const copyEvents = events;
      var returnEvents = [];

      copyEvents.forEach(item => {
        if ((item.location != null && item.location.length != 0) && (locations.some((e) => e.value === item.location))) {
          returnEvents.push(item);
        }
      });

      return returnEvents;
    }

    return events;
  }

  toggleEventMenu(index) {
    const tempShowEventFilter = [...this.state.showEventFilter];
    tempShowEventFilter[index] = !tempShowEventFilter[index];
    this.setState({ showEventFilter: tempShowEventFilter });
  }

  toggleCheckedEvent = (item, index) => {

    const templocation = [...this.state.filterLocation];
    templocation[index].checked = !templocation[index].checked;
    this.setState({ filterLocation: templocation });

    if (item.checked === false) {
      let filteredArray = this.state.filteredEvents.filter(event => event.location != item.name);
      this.setState({
        filteredEvents: filteredArray,
      });

    } else if ((!(this.state.selectedSpecialistTitle.length > 0) | !(this.state.startDate !== "" | this.state.endDate !== ""))) {
      let date = this.calendar.current.getDetails();
      this.getEventData(date.year, date.month);

    } else {
      let filteredArray = this.state.currentEvents.filter(event => event.location === item.name);
      this.setState({
        filteredEvents: filteredArray,
      });

    }
  }

  toggleModal = (e) => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  handleCalendarChanges = (dates) => {
    if (dates.mode === 'monthlyMode') {
      this.getEvents(dates.year, dates.month);
      this.getEventData(dates.year, dates.month);
      let currentCalendarDate = this.calendar.current.getDetails()
      this.setState({
        currentCalendarDate: new Date(currentCalendarDate.year, currentCalendarDate.month, "01"),
      });
    }
  }

  handleEventSearch = (searchString) => {
    let containsTheSearchStringOnlyWhitespaces = searchString.replace(/\s/g, '').length === 0
    if (searchString.length > 0 && !containsTheSearchStringOnlyWhitespaces) {

      // check if the search string is a date
      let date = new Date(searchString);
      if (date instanceof Date && !isNaN(date)) {
        searchString = date.toISOString();
      }

      let filteredEvents = this.filter(searchString, this.state.currentEvents);

      this.setState({
        filteredEvents: filteredEvents,
        searchedEvents: true,
        searchedType: true,
      })
      localStorage.setItem("searchData", searchString);
    }
  }

  handleCalendarEventClick = (eventId) => {
    const selectedEvent = this.state.currentEvents.find(event => event.id == eventId);
    this.setState({ selectedEvent: selectedEvent })
    this.setState({ showModal: true });
  }

  handleAreasChange = (selectedOption) => {
    this.setState({ selectedSpecialistTitle: selectedOption });
    let results = [];
    if (selectedOption.length > 0) {
      // Filtered only events between start and end date
      // Selected Calendar Start set
      // Otherwise take the first day of the month

      var selectedCalendarStartDate = ((this.state.startDate != "") ? this.state.startDate : this.state.currentCalendarDate)
      var selectedCalendarEndDate = ((this.state.endDate != "") ? this.state.endDate :
        new Date(this.state.currentCalendarDate.getFullYear()
          , (this.state.currentCalendarDate.getMonth() + 1), 1).toISOString())

      if (selectedOption.length > 1) {
        results = this.getSearchEventsByArea(selectedOption[selectedOption.length - 1].value);
        results = results.filter(e => e.startDate >= selectedCalendarStartDate && e.endDate < selectedCalendarEndDate)

        this.setState({
          filterLocation: this.setfilterElements(results),
          filteredEvents: this.state.filteredEvents.concat(results),
        })
      } else {
        results = this.getSearchEventsByArea(selectedOption[selectedOption.length - 1].value);
        results = results.filter(e => e.startDate >= selectedCalendarStartDate && e.endDate < selectedCalendarEndDate)

        this.setState({
          filterLocation: this.setfilterElements(results),
          filteredEvents: results,
        })
      }
    } else {
      this.setState({
        filteredEvents: results,
        searchEvent: false,
        searchedType: false,
      })
      let date = this.calendar.current.getDetails();
      this.getEventData(date.year, date.month);
    }
  }

  handleLocationChange = (selectedOptions) => {
    this.setState({ selectedFilterLocation: selectedOptions });
    let results = this.state.currentEvents;
    if (selectedOptions.length > 0) {
      // Filtered only events between start and end date
      // Selected Calendar Start set
      // Otherwise take the first day of the month

      var selectedCalendarStartDate = ((this.state.startDate != "") ? this.state.startDate :
        new Date(this.state.currentCalendarDate.getFullYear()
          , (this.state.currentCalendarDate.getMonth()), 1).toISOString())
      var selectedCalendarEndDate = ((this.state.endDate != "") ? this.state.endDate :
        new Date(this.state.currentCalendarDate.getFullYear()
          , (this.state.currentCalendarDate.getMonth() + 1), 1).toISOString())

      if (selectedOptions.length > 1) {
        results = results.filter(e => e.startDate >= selectedCalendarStartDate && e.endDate < selectedCalendarEndDate)
        results = this.filterByLocation(results, selectedOptions)
        this.setState({
          filteredEvents: this.state.filteredEvents.concat(results),
        })
      } else {
        results = results.filter(e => e.startDate >= selectedCalendarStartDate && e.endDate < selectedCalendarEndDate)
        results = this.filterByLocation(results, selectedOptions);
        this.setState({
          filteredEvents: results,
        })
      }
    } else {
      this.setState({
        filteredEvents: results,
        searchEvent: false,
        searchedType: false,
      })
      let date = this.calendar.current.getDetails();
      this.getEventData(date.year, date.month);
    }
  }

  handleSearchByStartDate(date) {
    if (date !== "") {
      this.setState({
        startDate: date,
      })
      this.getSearchEventsDate("startDate", date);
    } else {
      this.setState({
        startDate: "",
      })
      let date = this.calendar.current.getDetails();
      this.getEventData(date.year, date.month);
    }
  }

  handleSearchByEndDate(date) {
    if (date !== "") {
      this.setState({
        endDate: date,
      })
      this.getSearchEventsDate("endDate", date);
    } else {
      this.setState({
        endDate: "",
      })
      let date = this.calendar.current.getDetails();
      this.getEventData(date.year, date.month);
    }
  }

  filter(text, array) {
    if (text === null || text === "") {
      return "";
    } else {
      return array.filter(e => JSON.stringify(e).toLowerCase().includes(text.toLowerCase()));
    }
  }

  render() {
    let { text, loading, filter } = this.props;
    return (
      <div className="container">
        <div className="row justify-content-center no-gutters">
          <div className="col-12">
            <div className="s130">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="inner-form searchResultSearchBar">
                  <div className="input-field first-wrap">
                    <div className="svg-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="auto"
                        height="auto"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                          {" "}
                        </path>
                      </svg>
                    </div>
                    <input
                      id="search"
                      type="text"
                      value={text}
                      placeholder={i18next.t("searchLabel")}
                      onChange={this.handleInputChange}
                      onKeyUp={this.handleKeyUp}
                      ref={(input) => (this.textInput = input)}
                    />
                  </div>
                  <div className="input-field second-wrap">
                    <Link to={resultLoader.path}>
                      <button
                        className="btn-search"
                        type="button"
                        onClick={this.onClick}
                      >
                        {" "}
                        {i18next.t("search")}{" "}
                      </button>
                    </Link>
                    {(this.state.Calendar === true || this.state.CalendarList) &&
                      <button
                        className="btn-search"
                        type="button"
                        onClick={this.onClick}
                        onChange={this.handleInputChange}
                      >
                        {" "}
                        {i18next.t("search")}{" "}
                      </button>
                    }
                  </div>
                  {loading ? "Searching" : ""}

                  {this.pr}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row justify-content-center ml-0 mr-0 pt-3">
          <div class="col-sm-auto c-align">
            <label className="" style={{}}>
              <input
                type="radio"
                value="External"
                name="radio"
                checked={this.state.External}
                onClick={this.onClickFilter}
              ></input>
              &nbsp;{i18next.t("WebSearch")}
            </label>
          </div>
          <div class="col-sm-auto c-align">
            <label className="" style={{}}>
              <input
                type="radio"
                value="Internal"
                name="radio"
                checked={this.state.Internal}
                onClick={this.onClickFilter}
              ></input>
              &nbsp; {i18next.t("ProductSearch")}
            </label>
          </div>
          <div class="col-sm-auto c-align">
            <label className="" style={{}}>
              <input
                type="radio"
                value="Calendar"
                name="radio"
                checked={this.state.Calendar}
                onClick={this.onClickCalendar}
              >
              </input>
              &nbsp;{i18next.t("EducationCalendar")}
            </label>
          </div>
          <div class="col-sm-auto c-align">
            <label className="" style={{}}>
              <input
                type="radio"
                value="Calendar"
                name="radio"
                checked={this.state.CalendarList}
                onClick={this.onClickCalendarList}
              >
              </input>
              &nbsp;{i18next.t("CalendarList")}
            </label>
          </div>
        </div>
        {(this.state.Internal === true) &&
          <div className="row justify-content-center ml-0 mr-0">
            <div className="col-sm-auto">
              <ul className="pagination">
                {pageNumbers.map((number) => (
                  <li key={number} className="page-item">
                    <a href="" onClick={() => {
                      localStorage.setItem("searchType", "Internal");
                      this.props.searchProduct(number);
                      localStorage.setItem("searchData", number);
                      // this.setState({text:number});
                      history.push(resultLoader.toUrl({ id: number }));
                    }}
                      className="page-link">
                      {number}
                    </a>
                  </li>
                ))}
                {/* {countryCode == "ch" ?
                  <li>
                    <a href="" className="page-link"
                      onClick={() => {
                        localStorage.setItem("searchType", "Internal");
                        this.props.searchProduct("SwissPedDose");
                        localStorage.setItem("searchData", "SwissPedDose");
                        history.push(resultLoader.toUrl({ id: "SwissPedDose" }));
                      }}>SwissPedDose
                    </a>
                  </li> :
                  null} */}
              </ul>
            </div>
          </div>}
        {(this.state.Calendar === true) &&
          <div className="row justify-content-center m-0 mt-5" style={{ backgroundColor: "hsl(206, 100%, 99%)" }}>
            <div className="col-sm-9 offset-sm-1">
              <Calendar
                ref={this.calendar}
                onChange={(dates) => {
                  this.handleCalendarChanges(dates)
                }}
                events={this.state.filteredEvents}
                onClickEvent={(eventId) => this.handleCalendarEventClick(eventId)}
              />
              <EventDetailsModal
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
                event={this.state.selectedEvent}
              />
            </div>
            <div
              className="col-sm-2 mt-0 mb-0 pt-0 pb-0 p-0"
              style={{ backgroundColor: "hsl(213, 69%, 98%)", borderLeft: "1px solid #e9e9e9" }}>
              <div class="filterheader-wrapper">
                <div
                  className="col mt-0 mb-0 pt-0 pb-0"
                  style={{ backgroundColor: "hsl(213, 69%, 98%)" }}>
                  <div class="accordion-wrapper">
                    <div class="event-search-input-wrapper">
                      <div className="event-search-input-element icon">
                        <BiCalendar size={20} color="rgb(128, 134, 138)" />
                      </div>
                      <div className="event-search-input-element header-text">
                        <p className="header-text">Filter</p>
                      </div>
                      <div className="event-search-input-wrapper-inline">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(this.state.searchedEvents === true) &&
                <div class="accordion-wrapper">
                  <div className="element-wrapper" onClick={() => {
                    this.toggleEventMenu(3)
                  }}>
                    <div className="element">
                      <button class="accordion">{i18next.t("MySearch")}</button>
                    </div>
                    <div className="element">{this.state.showEventFilter[3] === true ? <BiChevronUp /> :
                      <BiChevronDown />}</div>
                  </div>
                  {
                    (this.state.showEventFilter[3] === true) ?
                      (<div class="panel mt-3">
                        <div key={0} className="label-1">
                          <div className="label-icon" onClick={() => {
                            this.setState({
                              searchedEvents: false,
                              searchedType: false,
                            })
                            let date = this.calendar.current.getDetails();
                            this.getEvents(date.year, date.month);
                          }}><IoIosClose className="cursor" /></div>
                          <div className="label-title-2">{localStorage.getItem("searchData")}</div>
                        </div>
                      </div>) : null
                  }
                </div>
              }
              {/*

              TODO: Clean up this code and all referenced state variables not used anymore

              {(this.state.searchedEvents === false) &&
                <div class="accordion-wrapper mt-3">
                  <div className="element-wrapper"
                    role="button"
                    onClick={() => { this.toggleEventMenu(0) }}>
                    <div className="element">
                      <button class="accordion">{i18next.t("Specializations")}</button>
                    </div>
                    <div className="element">
                      {this.state.showEventFilter[0] === true ? <BiChevronUp /> : <BiChevronDown />}
                    </div>
                  </div>
                  {
                    this.state.showEventFilter[0] === true ?
                      (<div class="panel">
                        <Select
                          name="specialistTitle"
                          className="specialistTitle"
                          closeMenuOnSelect={false}
                          components={makeAnimated()}
                          isMulti='true'
                          value={this.state.selectedSpecialistTitle}
                          options={this.state.professionalAreaList}
                          onChange={this.handleAreasChange}
                        />
                      </div>) : null
                  }
                </ div>
              } */}
              {(this.state.searchedEvents === false) &&
                <div class="accordion-wrapper mt-3">
                  <div className="element-wrapper"
                    onClick={() => {
                      this.toggleEventMenu(1)
                    }}>
                    <div className="element">
                      <button class="accordion">{i18next.t("Locations")}</button>
                    </div>
                    <div className="element">
                      {this.state.showEventFilter[1] === true ? <BiChevronUp value={{ color: 'blue' }} /> :
                        <BiChevronDown />}
                    </div>
                  </div>
                  {
                    this.state.showEventFilter[1] === true ?
                      (<div class="panel">
                        <Select
                          name="filterLocation"
                          className="filterLocation"
                          closeMenuOnSelect={false}
                          components={makeAnimated()}
                          isMulti='true'
                          value={this.state.selectedFilterLocation}
                          options={this.state.filterLocation}
                          onChange={this.handleLocationChange}
                        />
                      </div>) : null
                  }
                  {/* {

                    TODO: Clean up this code and all referenced state variables not used anymore

                    this.state.showEventFilter[1] === true ?

                      (<div class="panel">
                        {this.state.filterLocation.map((item, index) => (
                          <div key={index} className="label-2" >
                            <div className="label-icon">
                              <input
                                type="checkbox"
                                name="locations"
                                checked={item.checked}
                                onClick={() => this.toggleCheckedEvent(item, index)}
                              />
                            </div>
                            <div className="label-title">{item.name}</div>
                          </div>
                        ))}
                      </div>) : null
                  } */}
                </ div>
              }
              {(this.state.searchedEvents === false) &&
                <div class="accordion-wrapper ">
                  <div className="element-wrapper"
                    onClick={() => {
                      this.toggleEventMenu(4)
                    }}>
                    <div className="element">
                      <button class="accordion">{i18next.t("StartDate")}</button>
                    </div>
                    <div className="element">
                      {this.state.showEventFilter[4] === true ? <BiChevronUp /> : <BiChevronDown />}
                    </div>
                  </div>
                  {
                    this.state.showEventFilter[4] === true ?
                      (<div class="panel">
                        <div key="1" className="label-2">
                          <Input
                            className="filter-date"
                            type="date"
                            name="startDate"
                            value={this.state.startDate}
                            min="2020-01-01"
                            max={(this.state.endDate !== "" ? this.state.endDate : "")}
                            onChange={(e) => {
                              this.handleSearchByStartDate(e.target.value);
                            }}
                          />
                        </div>
                      </div>) : null
                  }
                </ div>
              }
              {(this.state.searchedEvents === false) &&
                <div class="accordion-wrapper ">
                  <div className="element-wrapper"
                    onClick={() => {
                      this.toggleEventMenu(5)
                    }}>
                    <div className="element">
                      <button class="accordion">{i18next.t("EndDate")}</button>
                    </div>
                    <div className="element">
                      {this.state.showEventFilter[5] === true ? <BiChevronUp value={{ color: 'blue' }} /> :
                        <BiChevronDown />}
                    </div>
                  </div>
                  {
                    this.state.showEventFilter[5] === true ?
                      (<div class="panel">
                        <div key="2" className="label-2">
                          <Input
                            className="filter-date"
                            type="date"
                            name="endDate"
                            value={this.state.endDate}
                            min={this.state.startDate !== "" ? this.state.startDate : ""}
                            onChange={(e) => {
                              this.handleSearchByEndDate(e.target.value);
                            }}
                          />
                        </div>
                      </div>) : null
                  }
                </ div>
              }
              {/*

              TODO: Clean up this code and all referenced state variables not used anymore

              {(specialistTitle !== null && this.state.searchedEvents === false) &&
                <div class="accordion-wrapper">
                  <div className="element-wrapper" onClick={() => { this.toggleEventMenu(2) }}>
                    <div className="element">
                      <button class="accordion">{i18next.t("MySpecialization")}</button>
                    </div>
                    <div className="element">{this.state.showEventFilter[2] === true ? <BiChevronUp /> : <BiChevronDown />}</div>
                  </div>
                  {
                    (this.state.showEventFilter[2] === true) ?
                      (<div class="panel">
                        {specialistTitle.map((item, index) =>
                          <div key={index} className="label-1" >
                            {/* <div className="label-icon"><p className="label-color" style={{ backgroundColor: "red" }} /></div> }
                            <div className="label-title-2" onClick={() => {
                              this.handleAreasChange(this.state.specialistTitle.concat(
                                [this.state.professionalAreaList.find(e => e.label === item)]
                              ));
                            }}>
                              {item}
                            </div>
                          </div>
                        )}
                      </div>) : null
                  }
                </div>
              } */}
            </div>
          </div>
        }
        {
          (this.state.CalendarList === true && this.state.searchedEvents === true && this.state.searchedType === true && this.state.filteredEvents.length > 0)
          ?
            <>
              <EventsCardsList events={this.state.filteredEvents} searchString={this.textInput.value}/>
            </>
          :
            (
              this.state.CalendarList === true && this.state.currentEvents.length > 0 &&
              <>
                <EventsCardsList events={this.state.currentEvents} />
              </>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  text: state.product.text,
  loading: state.product.loading,
  filter: state.product.filter,
});

export default connect(mapStateToProps, {
  searchProduct,
  fetchProducts,
  setLoading,
  saveKeyword,
  filterProducts,
})(SearchBar);
