import axios from 'axios';
import { API } from '../urls';

export const doctorService = {
    getDoctor,
    getDoctorUserByUserId,
    getAllDoctors,
    newDoctor,
    statusChange,
    getChatroomDoctors,
    getDoctorBranchesChart,
    searchChatroomDoctors,
    deleteDoctor
};

function getDoctor(id) {
    let url = API.doctorsLocation.toUrl();
    return axios.get(url)
      .then(doctors => {
        return doctors.filter(doctor => doctor.id === id)[0];
      })
  }

function getDoctorUserByUserId(userId) {
    return axios.get(API.getDoctorUserLocation.toUrl({userId}));
  }

  function getDoctorBranchesChart() {
    let url = API.doctorBranchesChartLocation.toUrl();
    return axios.get(url);
  }

function getAllDoctors() {
  let url = API.doctorsLocation.toUrl();
  return axios.get(url)
}

function getChatroomDoctors(){
  let url = API.chatroomDoctorsLocation.toUrl();
  return axios.get(url)
}

function searchChatroomDoctors(id){
  let url = API.chatroomDoctorsSearchLocation.toUrl({id:id});
  return axios.get(url)
}

function newDoctor(data) {
  let url = API.newDoctorLocation.toUrl();
  return axios.post(url, data)
}

function statusChange(id) {
  let url = API.doctorsStatusChangeLocation.toUrl({id:id});
  return axios.put(url)
}

function deleteDoctor(id) {
  let url = API.doctorsDeleteLocation.toUrl({id:id});
  return axios.delete(url)
}
