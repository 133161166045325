import "./docregister.css";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import React from "react";
import { countryCode } from "../../../config.example";
import Select from 'react-select';
import { apiBaseUrl } from "../../../config.example";
import axios from "axios";
import { cogoToastHelper } from "../../../_helpers/cogoToastHelper";
import i18next from "i18next";
import makeAnimated from 'react-select/lib/animated';
import { sourcesService } from "../../../_services/sources.service";
import { voucherServices } from "../../../_services/voucher.service";
import { doctornetworkService } from "../../../_services/doctornetwork.service";

export default class DocRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      professions: [],
      professionalArea: [],
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      userName: "",
      confirmPassword: "",
      errorMessage: "",
      profilePicture: "",
      specialistTitle: "",
      academicTitle: "",
      employer: "",
      message: "",
      gln: "",
      errmsg: "",
      street: "",
      city: "",
      voucher: "",
      professionalAreaList: [],
      selectedAreas: [],
      gnlerror: false,
      sponsorData: {},
      sponsorModal: false,
      img: "",
      doctorNetworks: [],
      doctorNetwork: 0
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkGLN(data) {
    if (countryCode == "de") {
      var self = this;
      const api = "/Doctor/register";
      this.setState({ loading: true });
      axios
        .post(
          apiBaseUrl + api,
          data,
          {
            headers: { "Content-Type": "application/problem+json" },
          },
          { withCredentials: true }
        )
        .then((response) => {
          self.setState({ loading: false });
          self.setState({ message: response });
          if (response.contains("Successfully"))
            self.Sponsor(data.voucher);

        })
        .catch((error) => {
          self.setState({ loading: false });
        });
    }
    else {
      var config = {
        method: "get",
        url:
          apiBaseUrl + "/account/CheckGln?gln=" + data.gln,
        headers: {},
      };

      var self = this;
      const api = "/Doctor/register";
      this.setState({ loading: true });
      axios(config)
        .then(function (response) {
          if (response == true) {
            axios
              .post(
                apiBaseUrl + api,
                data,
                {
                  headers: { "Content-Type": "application/problem+json" },
                },
                { withCredentials: true }
              )
              .then((response) => {
                self.setState({ loading: false });
                self.setState({ message: response });
                if (response.contains("Successfully")) {
                  self.Sponsor(data.voucher);
                }

              })
              .catch((error) => {
                self.setState({ loading: false });
              });
          }

          else {

            self.setState({ gnlerror: true });

            cogoToastHelper.error([i18next.t("GLNnotAllowed")]);

            self.setState({ loading: false });

          }
        })
        .catch(function (error) {
          self.setState({ gnlerror: true });
          cogoToastHelper.error([i18next.t("GLNnotFound")]);
          self.setState({ loading: false });
        });
    }
  }

  Sponsor(voucher) {
    voucherServices.Sponsor(voucher).then(
      (response) => {
        this.setState({ sponsorData: response }, () => this.setState({ sponsorModal: true }));
      },
      (error) => {

      }
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    const api = "/Doctor/register";
    const {
      email,
      password,
      confirmPassword,
      lastName,
      firstName,
      userName,
      errorMessage,
      specialistTitle,
      academicTitle,
      employer,
      gln,
      street,
      city,
      voucher,
      doctorNetworkId
    } = this.state;
    localStorage.setItem("UserInfoEmail", this.state.email);
    var resultEmail = localStorage.getItem("UserInfoEmail")

    var selectedAres = this.state.selectedAreas;
    var listAreas = selectedAres.map(area => {
      return area.label + ",";
    });


    if (password !== confirmPassword) {
      cogoToastHelper.info("Password Doesn't Match");
    } else if (selectedAres.length === 0) {
      cogoToastHelper.info("Select your professional area");
    } else {
      let self = this;

      var postData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        userName: userName,
        confirmPassword: confirmPassword,
        specialistTitle: listAreas.join(),
        academicTitle: academicTitle,
        employer: employer,
        gln: gln,
        street,
        city,
        voucher,
        doctorNetworkId: doctorNetworkId
      };

      this.checkGLN(postData);
    }
  }

  componentDidMount() {
    this.getDropdownValues();
  }

  getDropdownValues() {
    this.setState({ submitting: true });
    sourcesService.professions().then(
      (response) => {
        this.setState({ professions: response });
      },
      (error) => {
        this.setState({ submitting: false });
      }
    );

    sourcesService.professionalArea().then(
      (response) => {
        this.setState({ professionalArea: response });

        var dataList = response.map(area => {
          let option = {
            value: area.id,
            label: area.area,
          }
          return option;
        });
        this.setState({ professionalAreaList: dataList });
      },
      (error) => {
        this.setState({ submitting: false });
      }
    );

    doctornetworkService.getAllDoctorNetworks().then(
      (response) => {
        this.setState({ doctorNetworks: response });
      },
      (error) => {
        this.setState({ submitting: false });
      }
    );
  }

  handleAreasChange = (selectedOption) => {
    this.setState({ selectedAreas: selectedOption });
  }

  render() {
    return (
      <div
        className="app  align-items-center jumbotron d-flex align-items-center"
        style={{
          backgroundImage: "url(/assets/img/doctors2.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div class="body1 container register">
          <div class="row">
            <div class="col-md-3 register-left">
              <img src="/assets/img/logo1.png" alt="" />
              <h3>{i18next.t("welcome")}</h3>
              <p>
                {i18next.t("welcomeNote")}
              </p>
              <Button
                style={{ backgroundColor: "#2d2762", color: "#fff" }}
                onClick={(event) => (window.location.href = "./login")}
              >
                {i18next.t("backtoLogin")}
              </Button>

              <br /><br /><br />
              <p>
                Internet : <a href="https://www.digital-doctor-house.com">www.digital-doctor-house.com</a><br />

                {i18next.t("phone")}: {countryCode == "de" ? "+49 661 965 944 99" : "+41 41 501 49 73"}<br />

                E-mail: <a href="mailto:support@digital-doctor-house.com">support@digital-doctor-house.com</a>
              </p>
              <br />
            </div>
            <div className="col-md-9 register-right">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <h3 className="register-heading">{i18next.t("register")}</h3>
                  <form
                    className="row register-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="col-12" style={{ textAlign: "center" }}>
                      {this.state.message !== "" && (
                        <small>
                          {this.state.message}
                          <br />
                          <br />
                        </small>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div
                        className="profilePic"
                        style={{ width: 220, height: 220 }}
                      >
                        <img
                          src={this.state.profilePicture}
                          alt=""
                          width="100"
                        />
                      </div>
                      <br />
                      <input
                        type="file"
                        onChange={this.onFileChange}
                        className="fileUpload"
                        style={{ width: "100%" }}
                        ref={(input) => (this.inputElement = input)}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          placeholder={i18next.t("firstname")}
                          value={this.state.firstName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          placeholder={i18next.t("lastname")}
                          value={this.state.lastName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="userName"
                          placeholder={i18next.t("username")}
                          value={this.state.userName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder={i18next.t("password")}
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          placeholder={i18next.t("confirmpassword")}
                          value={this.state.confirmPassword}
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder={i18next.t("youtemail")}
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="employer"
                          placeholder={i18next.t("youremployer")}
                          value={this.state.employer}
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>{i18next.t("speciality")}</label>
                        <Select
                          closeMenuOnSelect={false}
                          components={makeAnimated()}
                          isMulti='true'
                          value={this.state.selectedAreas}
                          options={this.state.professionalAreaList}
                          onChange={this.handleAreasChange}
                        />
                      </div>

                      <div className="form-group">
                        <select
                          type="text"
                          name="academicTitle"
                          id="academicTitle"
                          placeholder={i18next.t("academictitle")}
                          value={this.state.academicTitle}
                          onChange={this.handleChange}>
                          {this.state.professions.map((title) => (
                            <option value={title.professionalTitle}>
                              {title.professionalTitle}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="gln"
                          required
                          placeholder={countryCode == "de" ? i18next.t("lanrNumberLabel") : i18next.t("glnNumberLabel")}
                          value={this.state.gln}
                          onChange={this.handleChange}
                        />
                      </div>

                      {countryCode == "de" && this.state.doctorNetworks.length > 0 && (
                        <div className="form-group">
                          <select
                            type="text"
                            name="doctorNetworkId"
                            id="doctorNetworkId"
                            placeholder={i18next.t("Doctor network")}
                            value={this.state.doctorNetworkId}
                            onChange={this.handleChange}>
                            <option value={0}>{i18next.t("No doctor network selected")}</option>
                            {this.state.doctorNetworks.map((network) => (
                              <option value={network.id}>
                                {network.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="voucher"
                          placeholder={i18next.t("voucher")}
                          value={this.state.voucher}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="info-circle">
                        <label>
                          <i className="fa fa-info-circle" aria-hidden="true" /> {i18next.t("Registration information")}
                        </label>
                        <div className="overlay">
                          <span style={{ color: "black", fontSize: "small" }}>
                            {i18next.t("password") + ": "}
                          </span>
                          <span style={{ color: "red", fontSize: "small" }}>
                            {i18next.t("passwordInfo")}
                          </span>
                          <br></br>
                          <span style={{ color: "black", fontSize: "small" }}>
                            {countryCode === "de" ? i18next.t("LANRNumberLabel") : (i18next.t("glnNumberLabel") + ": ")}
                          </span>

                          <span style={{ color: "red", fontSize: "small" }}>
                            {countryCode === "de" ? i18next.t("LANRauthentication") : i18next.t("GLNauthentication")}
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <strong>
                          {/*only show trial note in switzerland*/}
                          {countryCode === "ch" ? i18next.t("Access to all features is granted during a 2-week trial period at no charge. Following the trial, a yearly subscription fee of 200 CHF is required to continue the service.") : ""}

                        </strong>
                      </div>
                      {(this.state.loading == false) &&
                        <Button
                          type="submit"
                          style={{ backgroundColor: "#2d2762", color: "#fff" }}
                        >
                          {i18next.t("register")}
                        </Button>}
                      {(this.state.loading == true) && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Spinner color="success" /></div>}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.gnlerror}
          className="modal-content modal-lg"
        >
          <ModalHeader
            toggle={() => this.setState({ gnlerror: false })}
          >{countryCode === "de" ? i18next.t("LANRvalidation") : i18next.t("GLNvalidation")}</ModalHeader>
          <ModalBody>{countryCode === "de" ? i18next.t("LANRnotValid") : i18next.t("GLNnotValid")}<br />


            Phone: {countryCode == "de" ? "+49 661 965 944 99" : "+41 41 501 49 73"} <br />
            E-mail: <a href="mailto:support@digital-doctor-house.com">support@digital-doctor-house.com</a>
            <br /><br /><br /></ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.sponsorModal}
          className="modal-content modal-lg"
        >
          <ModalHeader
            toggle={() => this.setState({ sponsorModal: false })}
          >
          </ModalHeader>
          <ModalBody>
            <center>
              {(this.state.sponsorData.companyName != undefined) && <img src={this.state.sponsorData.logo} />}
              <p>{i18next.t("sponsoredBy")} {(this.state.sponsorData.companyName !== undefined) ? this.state.sponsorData.companyName : ""}</p>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => this.setState({ sponsorModal: false })}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}