import { FETCH_ARTICLE, LOADING_ARTICLE } from '../_actions/types';

const initialState = {
    loading: false,
    article: {
        title: `Cancer Research in 2018 -- Advances that will propel us closer to cures`,
        content: `
        <p class="lead">
            Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis
            natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Nullam id dolor id nibh ultricies vehicula.
</p>
        <p>
            Cum sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Donec ullamcorper nulla non metus auctor
            fringilla. Duis mollis, est non commodo luctus, nisi erat
            porttitor ligula, eget lacinia odio sem nec elit. Donec
            ullamcorper nulla non metus auctor fringilla.
</p>
        <div class="well ">
            <large>This is image</large>
            <img src />
        </div>
        <p>
            Maecenas sed diam eget risus varius blandit sit amet non magna.
            Donec id elit non mi porta gravida at eget metus. Duis mollis, est
            non commodo luctus, nisi erat porttitor ligula, eget lacinia odio
            sem nec elit.
</p>
        <blockquote>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                posuere erat a ante.
</p>
            <footer>
                Someone famous in <cite title="Source Title">Source Title</cite>
            </footer>
        </blockquote>
        <p>
            Cum sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Donec ullamcorper nulla non metus auctor
            fringilla. Duis mollis, est non commodo luctus, nisi erat
            porttitor ligula, eget lacinia odio sem nec elit. Donec
            ullamcorper nulla non metus auctor fringilla.
</p>
        <p>
            Maecenas sed diam eget risus varius blandit sit amet non magna.
            Donec id elit non mi porta gravida at eget metus. Duis mollis, est
            non commodo luctus, nisi erat porttitor ligula, eget lacinia odio
            sem nec elit.
</p>
        <blockquote>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                posuere erat a ante. nascetur ridiculus mus. Donec ullamcorper
                nulla non metus auctor fringilla. Duis mollis, est non commodo
                luctus, nisi erat porttitor ligula, eget lacinia odio sem nec
                elit. Donec ullamcorper nulla non metus auctor fringilla.
</p>
        </blockquote>
        <blockquote>
            <p>
                Maecenas sed diam eget risus varius blandit sit amet non magna.
                Donec id elit non mi porta gravida at eget metus. Duis mollis,
                est non commodo luctus, nisi erat porttitor ligula, eget lacinia
                odio sem nec elit.
</p>
        </blockquote>
`,
        category: 'Cancer Research',
        publicationDate: '25.05.2019',
        author: {
            name: 'Dr. Gary Gilliland',
            image: 'image source',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
            url: '',
        }
    }

}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ARTICLE:
            return {
                ...state,
                article: action.payload,
                loading: false,
            };


        default:
            console.log("Default action")
            return state
    }
}