import {
  SEARCH_PRODUCTS,
  FETCH_PRODUCTS,
  LOADING,
  PAGINATE,
  PRODUCT_DETAILS,
  FETCH_ARTICLE
} from "./types";
import axios from "axios";
import { results } from "./data/result";
import { store } from "../_helpers/store";
import { userService } from "../_services";
import { apiBaseUrl } from "../config.example";

// export const detailsProduct = id => dispatch => {
//   console.log("called");
//   const token = userService.getToken();
//   const api = `/product/${id}`;

//   axios
//     .get(api, { headers: { Authorization: `Bearer ${token}` } })

//     .then(res => {
//       console.log("hello", api);
//       try {
//         dispatch({
//           type: PRODUCT_DETAILS,
//           payload: res
//         });
//       } catch (err) {
//         console.log("error", err);
//       }
//     });
// };

export function detailsProduct(id) {
  const token = userService.getToken();
  const api = `/product/${id}`;

  axios
    .get(apiBaseUrl + api, { headers: { Authorization: `Bearer ${token}` } })

    .then(res => {
      try {
        store.dispatch({
          type: PRODUCT_DETAILS,
          payload: res
        });
      } catch (err) {
        console.log("error", err);
      }
    });
}
