import React, { useEffect, useState } from "react";
import SearchBar from "./search/search-bar";
import Footer from "./footer";
import NavBar from "./navBar";

export default function Search() {
  const [searchType, setSearchType] = useState(undefined);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const searchTypeQuery = query.get('searchType');
    setSearchType(searchTypeQuery);
  }, [])

  return (
    <React.Fragment>
      <NavBar />

      <div style={{ minHeight: '77vh' }}>
        {searchType !== undefined?
        <SearchBar searchType={searchType} />
        : null}
      </div>
      
      <Footer />
    </React.Fragment>
  );
}
