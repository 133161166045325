import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers/history";
import axios from "axios";
import { LoginLocation, DashboardLocation } from "../locations";
import { API } from "../urls";
// import { cogoToastHelper } from "../_helpers/cogoToastHelper";

export const userActions = {
  login,
  logout,
  register,
  docRegisteration
};

// function login(username, password) {
//     return dispatch => {
//         dispatch(request({ username }));

//         userService.login(username, password)
//             .then(
//                 user => {
//                     dispatch(success(user));
//                     history.replace('/');
//                 },
//                 error => {
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
//     function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
// }

// function register(user) {
//     return dispatch => {
//         dispatch(request(user));
//         userService.register(user)
//             .then(
//                 user => {
//                     dispatch(success());
//                     history.push(LoginLocation.path);
//                     dispatch(alertActions.success('Registration successful'));
//                 },
//                 error => {
//                     //console.log(error)
//                     dispatch(failure(error.toString()));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
//     function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
// }

function login(data, actions, dispatch) {
  return axios.post(API.loginLocation.toUrl(), data).then(
    response => {
      localStorage.setItem("user", JSON.stringify(response));
      dispatch(success(response));
      history.replace(DashboardLocation.path);
    },
    error => {
      actions.setSubmitting(false);
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
      localStorage.setItem("message1",error.toString());
      console.log("The returned message is "+localStorage.getItem("message1"));
      return error;
    }
  );

  //function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function register(data, actions, dispatch) {
  return axios.post(API.registrationLocation.toUrl(), data).then(
    user => {
      actions.setSubmitting(false);
      history.push(LoginLocation.path);
      dispatch(alertActions.success("Registration successful"));
    },
    error => {
      actions.setSubmitting(false);
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
    }
  );

  // function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
  // function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function docRegisteration(data, action, dispatch) {
  return axios.post(API.newDoctorLocation.toUrl(), data).then(
    user => {
      action.setSubmitting(false);
      history.push(LoginLocation.path);
      dispatch(alertActions.success("User Registered Successfully"));
    },
    error => {
      action.setSubmitting(false);
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
    }
  );

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  history.push(LoginLocation.path);
  console.log("Logged out successfully");
  // cogoToastHelper.success("Logged out successfully");
  return { type: userConstants.LOGOUT };
}

