import React from 'react';

export default function Rating(props) {
    let rating = props.rating;
    let stars = [];

    for (let i = 0; i < rating; ++i) {
        stars.push(<i className="fa fa-star fa-1x text-primary" />)
    }

    for (let i = 0; i < 5-rating; ++i) {
        stars.push(<i className="fa fa-star fa-1x text-muted" />)
    }
    
    return (
        <React.Fragment>
            { stars }
        </React.Fragment>
    );
}