exports.login="Login";
exports.register="Register";
exports.submit="Submit";
exports.emailvalidation="Provide your username or email address";
exports.forgotpassword="Forgot Password";
exports.forgottenpassword="Forgotten Password";
exports.loginLabel="The Global Platform for Literature Search,Acquisition and Exchange of Medical Information";
exports.register="Register";
exports.welcome="Welcome";
exports.welcomeNote="You are 30 seconds away from being a part of Digital Doctor House!";
exports.backtoLogin="Back To Login";
exports.passwordInfo="Passwords should have a numeric number, an uppercase alphabet, a lowercase alphabet, and should be 8 letters long";
exports.glnNumber="For authenticating the doctors legality, We ask you for your GNL nummer to validate your identity";
exports.glnNumberLabel="GLN Number";
exports.regInfo="Information For Registeration";
exports.trailNote="Try 2 months free of charge: You will receive a request two weeks before the end of your trial period asking if you would like to continue using the tools and from then on at a subscription fee of CHF 200 for 12 months.";
exports.speciality="Medical speciality (several possible)";
exports.firstname="First Name";
exports.lastname="Last Name";
exports.youtemail="Your Email";
exports.password="Password";
exports.confirmpassword="Comfirm Password";
exports.myprofile="My Profile";
exports.profile="Profile";
exports.sources="My Sources";
exports.newsources="New Source";
exports.chatroom="Chatroom";
exports.messages="Messages";
exports.support="Support";
exports.logout="Logout";
exports.searchLabel="What are you looking for?";
exports.WebSearch="Web Search";
exports.ProductSearch="Product Search";
exports.anonymous="Check the box to make your account anonymous";
exports.updateProfile="Update Profile";
exports.domain="Domain";
exports.Comment="Comment";
exports.ddhsouce="DDH Source";
exports.pending="Pending";
exports.completed="Completed";
exports.subscribe="Subscribe";
exports.unsubscribe="Unsubscribe";
exports.requestSource="Request New Source";
exports.domainurl="Domain Url";
exports.seemore="See More";
exports.readmore="Read More";
exports.showmoreresult="Show more results";
exports.search="Search";
exports.Literature="Scientifique";
exports.TechnicalInformation="Informations techniques";
exports.Indications="Indications";
exports.OtherInformation="Autres informations";
exports.images="Images";
exports.links="Links";
exports.videos="Vidéos";
exports.PatientInformation="Informations pour les patients";
exports.Packs="Packs";
exports.message="Message";
exports.video="Video";
exports.overview="Vue d'ensemble";
exports.details="Détails";
exports.permissions="Permissions";
exports.composemessage="Compose New Message";
exports.sendreply="Send Reply";
exports.entermessage="Type your message here";
exports.supports="Support Desk of Digital Doctor House (Kundendienst von Digital Doctor House) Please write your message here. (Bitte beschreiben Sie uns hier ihr Anliegen.)";
exports.sendmsg="Send a message";
exports.sendmsgsub="Contact us by sending a message directly to as and we will get back to you shortly";
exports.subject="Message Subject";
exports.sendmsgBtn="Send Message";
exports.phone="Phone";
exports.emailLabel="E-mail";
exports.specialitychatroom="Speciality Chatroom";
exports.choosechatroom="Choose your Speciality";
exports.mychatroom="My chat group";
exports.otherChats="Other chats";
exports.invitedoctors="Invite Doctors";
exports.selectDoctor="Select Doctors";
exports.groupmembers="Group Members";
exports.status="Status";
exports.createddate="Created Date";
exports.groupdesc="Group Description";
exports.Participant="Participant Name";
exports.Keyword="Keyword";
exports.chatgroupname="Chat Group Name";
