import React, { useState } from "react";

import Footer from "./footer";
import Loader from "react-loader-spinner";
import NavBar from "./navBar";
import axios from "axios";

function GPT() {
  const [userInput, setUserInput] = useState("");
  const [response, setResponse] = useState("");
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const result = await axios.post("https://gpt.digital-doctor-house.com/chatbot", {
        input_text: userInput,
      });

      if (result.response) {
        setResponse(result.response);
        const uniqueSources = removeDuplicateSources(result.sources || []);
        setSources(uniqueSources);
      } else {
        setResponse("Unexpected response format from the server.");
        setSources([]);
      }
    } catch (error) {
      console.log(error);
      alert("Error sending request:", error.toString());
      setResponse("Error getting response from chatbot.");
      setSources([]);
    }
    setLoading(false);
  };

  const removeDuplicateSources = (sourcesArray) => {
    const uniqueUrls = new Set();
    const uniqueSources = [];
  
    for (const source of sourcesArray) {
      const url = source.Url;
      if (!uniqueUrls.has(url)) {
        uniqueUrls.add(url);
        uniqueSources.push(source);
      }
    }
  
    return uniqueSources;
  };


  return (
    <React.Fragment>
      <NavBar />
      <div className="row justify-content-center no-gutters">
        <div className="col-12">
          <div className="s130">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="inner-form searchResultSearchBar">
                <div className="input-field first-wrap">
                  <div className="svg-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="auto"
                      height="auto"
                      viewBox="0 0 24 24"
                    >
                      <path d="..."></path>
                    </svg>
                  </div>
                  <input
                    id="search"
                    type="text"
                    value={userInput}
                    placeholder="Enter your prompt here..."
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-field second-wrap">
                  <button className="btn-search" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="response-container">
        {loading ?
          <Loader type="ThreeDots" color="#00BFFF" /> :
          <div className="response-text">{response}</div>
        }
      </div>
      {(sources.length == 0 || loading) ?
        <></> :
        <>
          <div className="source-list">
          <h4>Sources and relevant information</h4>
            {sources.map((source, index) => (
              <><br></br><a key={index} className="response-source" href={source.Url} target="_blank">{source.Url}</a></>
            ))}
          </div>
        </>
      }

      <Footer />
    </React.Fragment>
  );
}

export default GPT;
