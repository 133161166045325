export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const LOADING = "LOADING";
export const LOADER = "LOADER";
export const PAGINATE = "PAGINATE";

export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const LOADING_ARTICLE = "LOADING_ARTICLE";
export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const USER_PROFILE = "USER_PROFILE";
export const KEYWORD = "USER_KEYWORD";
export const FILTERED_PRODUCTS = "FILTERED_PRODUCTS";
