import {
    Button
} from "reactstrap";
import React from 'react';
import { countryCode } from "../config.example";
import i18next from "i18next";

const Imprint = () => (
    <div
        className="app  align-items-center jumbotron d-flex align-items-center"
        style={{
            backgroundImage: "url(/assets/img/doctors2.jpg)",
            backgroundSize: "cover",
        }}
    >
        <div class="body1 container register">
            <div class="row">
                <div class="col-md-3 register-left">
                    <img src="/assets/img/logo1.png" alt="" />
                    <h3>{i18next.t("welcome")}</h3>
                    <p>
                        {i18next.t("welcomeNote")}
                    </p>
                    <Button
                        style={{ backgroundColor: "#2d2762", color: "#fff" }}
                        onClick={(event) => (window.location.href = "./login")}
                    >
                        {i18next.t("backtoLogin")}
                    </Button>

                    <br /><br /><br />
                    <p>
                        Internet : <a href="https://www.digital-doctor-house.com">www.digital-doctor-house.com</a><br />

                        {i18next.t("phone")}: {countryCode == "de" ? "+49 661 965 944 99" : "+41 41 501 49 73"}<br />

                        E-mail: <a href="mailto:support@digital-doctor-house.com">support@digital-doctor-house.com</a>
                    </p>
                    <br />
                </div>
                <div className="col-md-9" style={{ background: '#f8f9fa' }}>
                    <h1>Impressum</h1>
                    <p>
                        Digital Doctor House AG<br></br>
                        Jöchlerweg 2<br></br>
                        6340 Baar<br></br>
                        Switzerland<br></br>
                        +41 79 239 34 29<br></br>
                        sebastian.wowra@digital-doctor-house.com
                    </p>
                    <p>
                        Vetretungsberechtigte Person:<br></br>
                        Sebastian Wowra, Präsident des Verwaltungsrates<br></br><br></br>
                        Digital Doctor House AG ist eingetragen im Handelsregister des Kantons Zug (UID: CHE-318.377.807).<br></br>
                    </p>

                </div>
            </div>
        </div>
    </div>
);

export default Imprint;