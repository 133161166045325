import { API } from "../urls";
import axios from "axios";

export const doctornetworkService = {
  getAllDoctorNetworks,
  getDoctorNetwork,
  addDoctorNetwork,
  updateDoctorNetwork
};

function getAllDoctorNetworks(){
  let url = API.getDoctorNetworks.toUrl();
  return axios.get(url);
}

function getDoctorNetwork(id){
  let url = API.getDoctorNetwork.toUrl({ id: id });
  return axios.get(url);
}

function addDoctorNetwork(data){
  let url = API.addDoctorNetwork.toUrl();
  return axios.post(url, data);
}

function updateDoctorNetwork(data){
  let url = API.updateDoctorNetwork.toUrl();
  return axios.post(url, data);
}
