import axios from "axios";
import { API } from "../urls";
import { format } from 'date-fns'
import i18next from "i18next";

export const userService = {
  login,
  register,
  logout,
  getToken,
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isDoctor,
  getUserId,
  updateUser,
  isCompanySubUser,
  statustext,
  sourcesStatus,
  getStats,
  activeStatus,
  crawledOrNot,
  //lastUpdated,
  checkSuperAdmin,
  getUser,
  fgt,Reset,
  gridStyle,
  payment,
  unsubscribe,
  updateUnsubStatus,
  checkAvailability,
  culture,
  inviteExternal
};

function culture(lang) {

  const data = {culture: lang};
  return axios.post(API.cultureLocation.toUrl(data));}


function checkValidity(email) {
  let url = API.checkValidityLocation.toUrl({ id: email });
  return axios.get(url);
}

function checkAvailability(email) {
  let url = API.checkAvailabilityLocation.toUrl({ id: email });
  return axios.get(url);
}

function updateUnsubStatus(email) {

  return axios.put(API.updateSubscriptionStatusLocation.toUrl({email:email}), {} ).then(response => {
    return response;
  });
}

function unsubscribe(email) {
  let url = API.unsubscribe1Location.toUrl({ email: email });
  return axios.get(url);
}


function payment(email, SubsID) {
  //const DateSubscription= new Date().toLocaleString()
  //const data = {Email: email, date: DateSubscription, SubscriptionId: SubscriptionId};
  const data = {email: email, SubsID: SubsID};


  return axios.post(API.paymentLocation.toUrl(data)).then(response => {
    console.log("The subscription reply is "+response); return response;
  });
}


function fgt(email) {

  return axios.put(API.recoveryLocation.toUrl({id:email}), {} ).then(response => {
    return response;
  });
}

function Reset(data) {
  return axios.put(API.resetLocation.toUrl(), data ).then(response => {
    return response;
  });
}

function login(email, password) {
  const data = {
    Email: email,
    Password: password
  };

  return axios.post(API.loginLocation.toUrl(), data).then(response => {
    localStorage.setItem("user", JSON.stringify(response));
    return response;
  });
}

function register(data) {
  return axios.post(API.registrationLocation.toUrl(), data);
}

function getStats() {
  return axios.get(API.statsLocation.toUrl());
}

//Doctor Registeration

function docRegister(data) {
  return axios.post(API.newDoctorLocation.toUrl(), data);
}

function updateUser(data) {
  return axios.post(API.updateDoctorLocation.toUrl(), data);
}

function logout() {
  localStorage.removeItem("user");
}

function getToken() {
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user && user.token ? user.token : "";

  return token;
}

function getUserId() {
  let user = JSON.parse(localStorage.getItem("user"));
  let id = user && user.id ? user.id : "";
  return id;
}

function getUser() {
  let user = JSON.parse(localStorage.getItem("user"));
  return user;
}


function checkSuperAdmin() {
  let user = JSON.parse(localStorage.getItem("user"));
  if(user.rolesDescription!==undefined)
    return (user.rolesDescription.includes("SuperAdmin"))?"true":"false";
  else
    return "false";
}

function isSuperAdmin() {
  let user = JSON.parse(localStorage.getItem("user"));

  return (
    user &&
    user.rolesDescription &&
    user.rolesDescription.includes("SuperAdmin")
  );
}

function isCompanyAdmin() {
  let user = JSON.parse(localStorage.getItem("user"));
  return (
    user && user.rolesDescription && user.rolesDescription.includes("Company")
  );
}

function isDoctor() {
  let user = JSON.parse(localStorage.getItem("user"));
  return (
    user &&
    user.rolesDescription &&
    user.rolesDescription.includes("Doctor") &&
    !isCompanyAdmin() &&
    !isSuperAdmin()
  );
}

function isCompanySubUser() {
  return !isCompanyAdmin() && !isSuperAdmin();
}

function isLoggedIn() {
  let b = localStorage.getItem("user") ? true : false;
  return b;
}

function statustext(status) {
  status=String(status);
  if(status==="0"){
    return "Deactivated";
  }else if(status==="1"){
    return "Active";
  }else if(status==="2"){
    return "Default";
  }else if(status==="3"){
    return "Company Default";
  }else{

  }
}

function sourcesStatus(status) {
  status=String(status);
  if(status==="0"){
    return [i18next.t("pending")];
  }else if(status==="1"){
    return [i18next.t("crawled")];
  }else if(status==="2"){
    return [i18next.t("requested")];
  }else if(status==="3"){
    return [i18next.t("disabled")];
  }else if(status==="4"){
    return [i18next.t("completed")];
  }else if(status==="5"){
    return [i18next.t("migrated")];
  }else{

  }
}

function activeStatus(active){
  return (active===true)?"Active":"Inactive";
}

function crawledOrNot(status) {
  status=String(status);
  //since we changed the table structure it is only active after the migration status 5
  if(status==="4"){
    return "Crawled";
  }else{
    return "Not Crawled";
  }
}

//function lastUpdated(lastUpdated){
//  return format(new Date(lastUpdated), "DD/MM/YYYY - HH:mm");
//}

function gridStyle(){
  return {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: 14,
      },
    },
    cells: {
      style: {
        borderLeft: '1px solid #eee'
      },
    }, rows: {
      style: {
        borderRight: '1px solid #eee'
      }
    }
  };
}
function inviteExternal(email, userID) {
  let url = API.inviteExternalLocation.toUrl({ email , userID});
  return axios.post(url);
}


