import React from "react";

import {connect} from "react-redux";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Loader from "react-loader-spinner";

import SearchBar from "./search-bar";
import NavBar from "../navBar";
import Footer from "../footer";
import {fetchProducts, filterProducts, saveKeyword, searchProduct, setLoading} from "../../../_actions/search.action";
import SearchResult from "./search-results";

// import SearchBar from "./search-bar";

class ContainingResult extends React.Component {
  // constructor(props) {
  //   super(props);
  //   console.log("My Props", props);
  // }

  componentWillMount() {
    // const query = queryString.parse(this.props.location.search).q;
    // console.log(query);
    const str = this.props.location.search;
    const res = str.split("?q=");
    var searchData = localStorage.getItem("searchData");

    console.log("search", searchData);
    this.props.searchProduct(searchData);
    this.props.fetchProducts(searchData);
    /*if(this.props.text!=="" || this.props.text!==":id"){
      console.log("search",this.props.text);
      this.props.searchProduct(this.props.text);
      this.props.fetchProducts(this.props.text);
    }else{
      console.log("search",res[1]);
      this.props.searchProduct(res[1]);
      this.props.fetchProducts(res[1]);
    }*/
  }

  componentDidUpdate(prevProps) {
    // will be true
    const locationChanged =
      this.props.location !== prevProps.location;
    console.log("new ==>", this.props.loader);
    if (this.props.location !== prevProps.location) {
      this.props.searchProduct(this.props.text);
      this.props.fetchProducts(this.props.text);
    }


    //
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log("nextProps", nextProps);
  // // if (nextProps.query !== this.props.query) {
  // //   this.setState({ query: nextProps.query });
  // //   return this.searchRepositories(nextProps.query);
  // // }
  // }

  onClick = e => {
    console.log("I am " + this.props.text);
    console.log("Search button clicked");

    // this.props.fetchProducts(this.props.text);
    // history.push(resultLoader.path);
    // this.props.saveKeyword(this.props.text);
    // this.props.setLoading();
  };

  render() {
    const {text, product} = this.props;
    // console.log("product", product);
    const {loading, loader} = this.props;
    console.log("loading", loading);
    console.log("product::", product);
    // const str = this.props.location.search;
    // const res = str.split("?q=");

    if (loading) {
      return null;
    }
    const products = this.props.product[0];

    // console.log("Containing", products);
    // console.log("PRODUCTI", product[0].title);
    return (
      <React.Fragment>
        <div class="container-fluid p-0">
          <div class="row no-gutters">
            <div class="col">
              <NavBar></NavBar>
            </div>
          </div>
          <SearchBar searchType={localStorage.getItem("searchType")}></SearchBar>
          <div class="row no-gutters">
            <div class="col">
              <div className="row">
                <div className="col-8 offset-2 resultSearchBar">

                </div>
              </div>
              {(loader === true) && <center><Loader type="ThreeDots" color="#00BFFF"></Loader></center>}
              <br/><br/>
              <div className="row">
                <div className="col-sm-8 col-md-6 offset-sm-2 offset-md-3">
                  <div class="row">
                    <div className="col-10 offset-1"/>
                    <SearchResult {...this.props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col">
              <Footer></Footer>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// console.log("products", this.props);
const mapStateToProps = state => ({
  loading: state.product.loading,
  loader: state.product.loader,
  text: state.product.text,
  product: state.product.products
});

export default connect(mapStateToProps, {
  searchProduct,
  fetchProducts,
  setLoading,
  saveKeyword,
  filterProducts
})(ContainingResult);
// export default ContainingResult;
