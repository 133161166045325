import axios from "axios";
import { API } from "../urls";

export const companyService = {
  getCompany,
  getAllCompanies,
  newCompany,
  getAllSubUsers,
  getSubUser,
  deleteCompany,
  newCompanySubUser,
  deleteCompanySubUser,
  updateCompanySubUser,
  companyStatus,
  updateCompanyUser,
  passwordUpdateCompany,
  getProductPermissions
};

function getCompany(id) {
  let url = API.companyLocation.toUrl({ id });
  return axios.get(url);
}

function getAllCompanies() {
  let url = API.companiesLocation.toUrl();
  return axios.get(url);
}

function getAllSubUsers() {
  let url = API.companySubUsersLocation.toUrl();
  return axios.get(url);
}

function getSubUser(id) {
  let url = API.subUserLocation.toUrl({ id });
  return axios.get(url);
}

function getProductPermissions(id){
  let url = API.getUserProductPermissionsLocation.toUrl({ id });
  return axios.get(url);
}

function newCompany(data) {
  let url = API.newCompanyLocation.toUrl();
  return axios.post(url, data);
}

function deleteCompany(id) {
  let url = API.companyLocation.toUrl({ id });
  return axios.delete(url);
}

function deleteCompanySubUser(id) {
  let url = API.subUserLocation.toUrl({ id });
  return axios.delete(url);
}

function newCompanySubUser(data) {
  let url = API.newCompanySubUserLocation.toUrl();
  return axios.post(url, data);
}

// function deleteCompanySubUser(id) {
//   return getAllSubUsers().then(companies => {
//     return companies.filter(users => users.id !== id);
//   });
// }

function updateCompanySubUser(data) {
  let url = API.updateCompanySubUser.toUrl({id:data.id});
  return axios.put(url, data);
}

function updateCompanyUser(data,id) {
  let url = API.updateCompanyUser.toUrl({id:id});
  return axios.put(url, data);
}

function passwordUpdateCompany(data,id) {
  let url = API.companyPasswordResetUser.toUrl({id:id});
  return axios.put(url, data);
}

function companyStatus(id) {
  let url = API.companyStatusLocation.toUrl({id:id});
  return axios.put(url);
}
