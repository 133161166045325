import { apiBaseUrl } from "../config.example";
import axios from "axios";
import { cogoToastHelper } from "./cogoToastHelper";
import urlJoin from "proper-url-join";
import { userActions } from "../_actions";
import { userService } from "../_services";

export function setupInterceptor() {
  let isAbsoluteURLRegex = /^(?:\w+:)\/\//;

  axios.interceptors.request.use(
    config => {
      if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = urlJoin(apiBaseUrl, config.url);
      }

      config.headers.Authorization = "Bearer " + userService.getToken();
      return config;
    },
    error => {
      cogoToastHelper.error(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      if (response.status !== 200) {
        const error =
          (response.data && response.data.message) || response.statusText;
        console.log(error);
        return Promise.reject(error);
      }
      if (response.data.data !== undefined && response.data.data !== null) {
        return response.data.data;
      } else if (response.data.data === null) {
        return response.data.message;
      } else {
        return response.data;
      }
    },
    function(error) {
      if (error.response && error.response.status === 401) {
        userActions.logout();
      }

      let errorMessage = "";
      if (error.response) {
        errorMessage =
          (error.response.data && error.response.data.message) ||
          error.response.statusText;
      } else {
      }
      if (Array.isArray(errorMessage)) {
        errorMessage = processErrorMessages(errorMessage);
      }

      return Promise.reject(errorMessage);
    }

  );
}

function processErrorMessages(messages) {
  return messages.map(message => message.description+" ");
}
