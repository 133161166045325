import "../dashboard/chatroom.css";

//websockets
import * as signalR from "@microsoft/signalr";

import { FiEye, FiSend, FiSettings } from "react-icons/fi";
//icons
import { HiOutlineUserGroup, HiSearch } from "react-icons/hi";
import React, { useCallback, useEffect, useRef, useState } from "react";

//elements
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { LogLevel } from "@aspnet/signalr";
import Modal from 'react-bootstrap/Modal';
import Navbar from "./navBar";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import Picker from 'emoji-picker-react';
import { RiMailAddLine } from "react-icons/ri";
import Row from 'react-bootstrap/Row';
import Select from "react-select";
import { TiAttachmentOutline } from "react-icons/ti";
import { animateScroll } from "react-scroll";
import { chatroomService } from "../../_services/chatroom.service";
import { cogoToastHelper } from "../../_helpers/cogoToastHelper";
import { connect } from "react-redux";
import { doctorService } from "../../_services/doctor.service";
//libraries
import i18next from "i18next";
import makeAnimated from "react-select/lib/animated";
import { messageHubUrl } from "../../config.example";
import { messagesService } from "../../_services/messages.service";
import moment from 'moment';
import { sourcesService } from "../../_services/sources.service";
import { userService } from "../../_services/user.service";
import { Container, Input, TextField } from "@material-ui/core";


function Chatroom({ profileParam }) {
  //information required to initially load the chatroom
  const [userId, setUserId] = useState();
  const [userChatId, setUserChatId] = useState("");
  const [profile, setProfile] = useState(profileParam);
  const [professionalAreas, setProfessionalAreas] = useState([]);
  const [chatGroups, setChatGroups] = useState([]);
  const [lastGroupAccess, setLastGroupAccess] = useState([]);
  const [lastDoctorAccess, setLastDoctorAccess] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [invitableDoctors, setInvitableDoctors] = useState([]);
  //const [storageState,setStorageState]=useState({chatroomType: "group", professionalAreaId: 0 , groupId: 0, doctorId: 0});
  //signal r connection
  const [connection, setConnection] = useState(null);

  //information of currently selected chatroom
  const [activeProfessionalArea, setActiveProfessionalArea] = useState();
  const [activeGroup, setActiveGroup] = useState();
  const [activeGroupID, setActiveGroupID] = useState();
  const [activeDoctor, setActiveDoctor] = useState();
  const [activeChatroomName, setActiveChatroomName] = useState("");
  const [activeChatroomType, setActiveChatroomType] = useState("");
  const [activeChatMessages, setActiveChatMessages] = useState([]);
  const [activeGroupMembers, setActiveGroupMembers] = useState();

  //modal windows
  const [showAcceptInvitationModal, setShowAcceptInvitationModal] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showNotification, setShowNotification] = useState(true)
  const [createGroupValidated, setCreateGroupValidated] = useState(false);
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [editGroupValidated, setEditGroupValidated] = useState(false);
  const [showInviteDoctorsModal, setShowInviteDoctorsModal] = useState(false);
  const [inviteDoctorsValidated, setInviteDoctorsValidated] = useState(false);
  const [inviteExternalDoctorsValidated, setInviteExternalDoctorsValidated] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchValidated, setSearchValidated] = useState(false);
  //updated

  //chat input
  const [textBoxValue, setTextBoxValue] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputFile = useRef(null);
  const [searchedItem, setSearchedItem] = useState("");
  const [filteredDoctors, setFilteredDoctors] = useState([]);



  //constructor
  useEffect(() => {
    localStorage.removeItem("chatroomType");
    loadUserId();
    loadProfessionalAreas();
    loadChatGroups();
    loadLastGroupAccess();
    loadDoctors();
    loadLastDoctorAccess();


    if (profile != undefined && profile.id != undefined) {
      setUserChatId(profile.firstName.substring(0, 1) + profile.firstName.substring(0, 1) + "_" + profile.id.substring(0, 5));
    }

    //build signal r websocket connection
    const newConnection = new HubConnectionBuilder()
      .withUrl(messageHubUrl,
        {
          // skipNegotiation: true,
          // transport: signalR.HttpTransportType.WebSockets
        })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  //on receive new data from websocket
  useEffect(() => {
    if (connection) {
      connection.start()
        .then(result => {
          console.log('Connected!');

          connection.on('ReceiveMessage', () => {
            loadChatGroups();
            loadLastGroupAccess();
            loadDoctors();
            loadLastDoctorAccess();


            const chatroomType = localStorage.getItem("chatroomType");
            //const chatroomType = storageState.chatroomType

            if (chatroomType == "professionalArea") {
              getProfessionalAreaAndGroupMessages("Chatroom_" + localStorage.getItem("professionalAreaId"));

            } else if (chatroomType == "group") {
              getProfessionalAreaAndGroupMessages("Chatroom_privateGroup_" + localStorage.getItem("groupId"));

            } else if (chatroomType == "doctor") {
              getPrivateMessages(localStorage.getItem("doctorId"), userId);

            }
          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }, [connection]);

  //initialize data for constructor
  const loadUserId = () => {
    setUserId(userService.getUserId());
  }

  const loadProfessionalAreas = () => {
    sourcesService.professionalArea().then(
      (response) => {
        setProfessionalAreas(response);
      }
    );
  }

  const loadChatGroups = () => {
    chatroomService.getGroup().then(
      (response) => {
        setChatGroups(response);
      }
    );
  }

  const loadLastGroupAccess = async () => {
    const response = await chatroomService.getLastGroupAccessForAll()
    setLastGroupAccess(response);

  }
  const loadLastDoctorAccess = async () => {
    const response = await chatroomService.getLastDoctorAccessForAll();
    setLastDoctorAccess(response);
  }
  // const postLastDoctorAccess = async () => {
  //   await chatroomService.postLastDoctorAccessForAll(receiverID, senderID)
  // }

  const loadDoctors = () => {
    doctorService.getChatroomDoctors().then(
      (response) => {
        setDoctors(response);
        var doctorOptions = [];
        for (var doctor in response) {
          //if (response[doctor].anonymous === 1 || response[doctor].anonymous === 0) {
          let option = {
            value: response[doctor].id,
            label: response[doctor].name,
          }
          doctorOptions.push(option);
          // }
        }
        setInvitableDoctors(doctorOptions);
      }
    );
  }

  //update currently selected chatroom
  const updateActiveProfessionalArea = (professionalArea) => {
    setActiveProfessionalArea(professionalArea);
    setActiveChatroomName(professionalArea.area);
    setActiveChatroomType("professionalArea");
    getProfessionalAreaAndGroupMessages("Chatroom_" + professionalArea.id);

    setLocalStorage("professionalArea", professionalArea.id, 0, 0);
  }

  const updateActiveGroup = async (group) => {
    setShowNotification(false)
    setActiveGroupID(group.id)
    if (activeGroupID)
      await createGroupAccess(activeGroupID, userId)
    setActiveGroup(group);
    setActiveChatroomName(group.name);
    setActiveChatroomType("group");
    getProfessionalAreaAndGroupMessages("Chatroom_privateGroup_" + group.id);
    updateActiveGroupMembers(group.id);
    await createGroupAccess(group.id, userId);
    setLocalStorage("group", 0, group.id, 0);
    setShowNotification(true)
  }

  const updateActiveDoctor = (doctor) => {
    setActiveDoctor(doctor);
    setActiveChatroomName(doctor.name);
    setActiveChatroomType("doctor");
    getPrivateMessages(doctor.id, userId);
    setLocalStorage("doctor", 0, 0, doctor.id);
  }

  const updateActiveGroupMembers = (groupId) => {
    messagesService.getChatroomMembers(groupId).then(
      (response) => {
        setActiveGroupMembers(response);
      }
    );
  }

  //tell the server that this user accessed this chatroom
  const createGroupAccess = async (groupId, userId) => {
    await chatroomService.createGroupAccess(groupId, userId)
    await loadLastGroupAccess();
  }


  //load chatroom messages for professional areas and groups
  const getProfessionalAreaAndGroupMessages = (roomId) => {
    messagesService.getChatroom(roomId).then(
      (response) => {
        setActiveChatMessages(response);

        animateScroll.scrollToBottom({
          containerId: "chatEntryArea",
        });
      }
    );
  }

  //load chatroom messages for private chats
  const getPrivateMessages = (roomid, receiver) => {
    messagesService.getChatroom1(roomid, receiver).then(
      (response) => {
        setActiveChatMessages(response);

        animateScroll.scrollToBottom({
          containerId: "chatEntryArea",
        });
      }
    );
  }

  //check if pressed key was enter to send messages
  const keyDownEnterChat = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  }



  //show/hide emoji picker
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  }

  //write selected emoji into textbox value
  const onEmojiClick = (event, emojiObject) => {
    if (emojiObject != null) {
      setTextBoxValue(textBoxValue + emojiObject.emoji);
    }

    setShowEmojiPicker(false);
  }

  //set local storage so the signal r callback can reload the current chat
  //this is used in all the update functions
  const setLocalStorage = (chatroomType, professionalAreaId, groupId, doctorId) => {
    localStorage.setItem("chatroomType", chatroomType);
    localStorage.setItem("professionalAreaId", professionalAreaId);
    localStorage.setItem("groupId", groupId);
    localStorage.setItem("doctorId", doctorId);
  }

  //forms and requests
  const handleCreateGroupSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      var formData = {
        name: event.target.elements.name.value,
        description: event.target.elements.description.value,
      };

      chatroomService.createGroup(formData).then(
        (response) => {
          cogoToastHelper.success(response);
          chatroomService.getGroup().then(
            (response) => {
              //createGroupAccess(response.groupId, response.userId)
              setChatGroups(response)
              setShowCreateGroupModal(false)
            },
            (error) => {
              cogoToastHelper.error(error);
            }
          );

        },
        (error) => {
          cogoToastHelper.error(error);
        }
      );
    }
    setCreateGroupValidated(true);
  }

  const acceptDeclineInvitationSubmit = (id, isAccept) => {
    chatroomService.acceptDeclineGroup(id, isAccept).then(
      (response) => {
        cogoToastHelper.success(response);
        setShowAcceptInvitationModal(false);
        loadChatGroups();
      },
      (error) => {
        cogoToastHelper.error(error);
      }
    );
  }

  const handleEditGroupSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      var formData = {
        description: event.target.elements.description.value,
      };

      chatroomService.updateGroup(activeGroup.id, formData).then(
        (response) => {
          cogoToastHelper.success(response);
          setShowEditGroupModal(false);
          loadChatGroups();
        },
        (error) => {
          cogoToastHelper.error(error);
        }
      );
    }
    setEditGroupValidated(true);
  }

  const handleInviteDoctorsSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      var doctorsArray = new Array(event.target.elements.doctors.value);
      if (doctorsArray[0] == '') {
        doctorsArray = new Array();
        event.target.elements.doctors.forEach(element => {
          if (element && element.value) {
            doctorsArray.push(element.value);
          }
        });
      }

      var formData = {
        groupId: activeGroup.id.toString(),
        doctors: doctorsArray
      };

      chatroomService.inviteToGroup(formData).then(
        (response) => {
          cogoToastHelper.success(response);
          setShowInviteDoctorsModal(false);
        },
        (error) => {
          cogoToastHelper.error(error);
        }
      );
    }
    setInviteDoctorsValidated(true);
  }

  const handleInviteExternalDoctorsSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      userService.inviteExternal(event.target.elements.externalEmail.value, userId).then(
        (response) => {
          cogoToastHelper.success(response);
          setShowInviteDoctorsModal(false);
        },
        (error) => {
          cogoToastHelper.error(error);
        });
    }
    setInviteExternalDoctorsValidated(true);
  }

  const handleSearchSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      var searchedWord = event.target.elements.searchedWord ? event.target.elements.searchedWord.value : "";

      if (searchedWord.length > 0) {
        messagesService.searchFeedback(userId, searchedWord).then(
          (response) => {
            setActiveChatMessages(response);
            setActiveChatroomType("search");
            setActiveChatroomName('"' + searchedWord + '"');
            setShowSearchModal(false);

            animateScroll.scrollToBottom({
              containerId: "chatEntryArea",
            });
          },
          (error) => {
            setShowSearchModal(false);
            cogoToastHelper.error(error);
          }
        );
      }
    }
    setInviteExternalDoctorsValidated(true);
  }

  const sendMessage = () => {
    if (textBoxValue != "" && activeChatroomType != "" && activeChatroomType != "search") {
      var chatId = "";
      var receiver = "";

      if (activeChatroomType == "professionalArea") {
        chatId = "Chatroom_" + activeProfessionalArea.id;
      } else if (activeChatroomType == "group") {
        chatId = "Chatroom_privateGroup_" + activeGroup.id;
      } else if (activeChatroomType == "doctor") {
        chatId = userId;
        receiver = activeDoctor.id;
      } else {
        return;
      }

      var formData = new FormData();
      formData.append("chatId", chatId);
      formData.append("sender", userId);
      formData.append("receiver", receiver);
      formData.append("message", textBoxValue);
      formData.append("contentType", 1);

      if (activeChatroomType == "professionalArea") {
        formData.append("ProfessionId", activeProfessionalArea.id);
      } else if (activeChatroomType == "group") {
        formData.append("ProfessionId", activeGroup.id);
        chatroomService.updateChatGroupForAlerting(activeGroup.id, userId);
      } else if (activeChatroomType == "doctor") {
        formData.append("ProfessionId", activeDoctor.id);
      }

      // if (activeChatroomType == "group") {
      //   chatroomService.updateChatGroupForAlerting(activeGroup.id, userId);
      // }

      messagesService.sendChat(formData).then(
        (response) => {
          if (activeChatroomType == "group") {
            createGroupAccess(activeGroup.id, userId);
          }

          setTextBoxValue("");

          if (response.id > 0 && response.professionId != "undefined") {
            chatroomService.pushNotification(response.message, userId, response.professionId);
          }

          if (connection.connectionStarted) {
            try {
              connection.send('SendMessage');
            }
            catch (e) {
              console.log(e);
            }
          }
          else {
            console.log('No connection to server yet.');
          }
        },
        (error) => {
          cogoToastHelper.error(error);
        }
      );
    }
  }

  const sendImage = (file) => {
    if (activeChatroomType != "" && activeChatroomType != "search") {
      var chatId = "";
      var receiver = "";

      if (activeChatroomType == "professionalArea") {
        chatId = "Chatroom_" + activeProfessionalArea.id;
      } else if (activeChatroomType == "group") {
        chatId = "Chatroom_privateGroup_" + activeGroup.id;
      } else if (activeChatroomType == "doctor") {
        chatId = userId;
        receiver = activeDoctor.id;
      } else {
        return;
      }

      var formData = new FormData();
      formData.append("chatId", chatId);
      formData.append("sender", userId);
      formData.append("receiver", receiver);
      formData.append("message", "");
      formData.append("contentType", 2);
      formData.append("file", file);

      if (activeChatroomType === "professionalArea") {
        formData.append("ProfessionId", activeProfessionalArea.id);
      }
      else if (activeChatroomType === "group") {
        formData.append("ProfessionId", activeGroup.id);
        chatroomService.updateChatGroupForAlerting(activeGroup.id, userId);
      }
      else if (activeChatroomType === "doctor") {
        formData.append("ProfessionId", activeDoctor.id);
      }

      // if (activeChatroomType == "group") {
      //   chatroomService.updateChatGroupForAlerting(activeGroup.id, userId);
      // }

      messagesService.sendChat(formData).then(
        (response) => {

          if (activeChatroomType == "group") {
            createGroupAccess(activeGroup.id, userId);
          }


          setTextBoxValue("");

          if (response.id > 0 && response.professionId != "undefined") {
            chatroomService.pushNotification(response.message, userId, response.professionId);
          }

          if (connection.connectionStarted) {
            try {
              connection.send('SendMessage');
            }
            catch (e) {
              console.log(e);
            }
          }
          else {
            alert('No connection to server yet.');
          }
        },
        (error) => {
          cogoToastHelper.error(error);
        }
      ).catch((error) => {
        console.log(error)
      });
    }
  }

  //helpers
  const buildProfessionalAreaEntry = (professionalArea) => {
    if (activeProfessionalArea && activeProfessionalArea.id === professionalArea.id)
      return (
        <div className="activeTab">
          <a onClick={() => updateActiveProfessionalArea(professionalArea)}>{professionalArea.area}</a>
        </div>
      );
    else
      return (
        <div>
          <a onClick={() => updateActiveProfessionalArea(professionalArea)}>{professionalArea.area}</a>
        </div>
      );
  }
  //group for updating local information
  const buildChatGroupEntry = (group, lastGroupAccess) => {
    {
      return (

        <a onClick={() => updateActiveGroup(group)}>
          <Row className="chatGroupEntry">
            <div className="chatGroupEntryImageContainer">
              <img src="/static/media/ddh-logo-min.f7c98230.png" className="chatGroupEntryImage" />
            </div>
            <div className="chatGroupEntryLabel">
              <div className="chatGroupEntryTitle">{group.name}</div>
              {group.description.substring(0, 35) + "..."}
            </div>

            {showNotification && lastGroupAccess && lastGroupAccess.groupId && (activeGroupID !== group.id) ?
              <>
                {moment(lastGroupAccess.lastAccessDateTime).isBefore(moment(group.lastSentDateTime)) || lastGroupAccess.id === 987654321 ?
                  <div className="chatGroupEntryNotificationContainer">
                    <NotificationImportant style={{ color: "#FF0000" }}></NotificationImportant>
                  </div>
                  : null}
              </>
              :
              null}
          </Row>
        </a>
      );
    }


  }

  //create html code for recurring elements
  const buildDoctorEntry = (doctor) => {
    var label = doctor.name;
    var occupation = doctor["specialist"];
    return (
      <a onClick={() => updateActiveDoctor(doctor)}>
        <Row className="chatGroupEntry">
          <div className="chatGroupEntryImageContainer">
            <img src="/assets/img/doctors.png" className="chatGroupEntryImage" />
          </div>
          <div className="chatGroupEntryLabel">
            <div className="chatGroupEntryTitle">{label}</div>
            {occupation != null ? occupation.substring(0, 35) + "..." : ""}
          </div>
        </Row>
      </a>
    );
  }

  const handleSearch = (event) => {
    setFilteredDoctors([])
    const searchedItem = event.target.value.toLowerCase();
    setSearchedItem(searchedItem);
    const filtered = doctors.filter((doctor) => doctor.name.toLowerCase().includes(searchedItem)
    )
    setFilteredDoctors(filtered);
  }

  const buildChatEntry = (chat) => {
    var label = chat.name;
    var occupation = chat["specialist"];
    var date = moment(chat.dateTime).add(moment().utcOffset(), 'minutes').format('lll');

    if (chat.userId == userId || chat.sender == userId) {
      return (
        <div className="col-12" style={{ marginTop: 10}}>
          <div className="t-line chatter-box speech-bubble" style={{ float: 'right', display: 'flex',  background: '#fff', color: '#1c2841', border: 'solid', borderColor: '#c1c1c1', borderRadius: '3%' }}>
            <div className="col-9 chat_message">
              <strong>{chat.sender}</strong>{(chat.contentType !== 2) ? <p>{chat.message}</p> : <img src={chat.file} width={"100%"} />}
            </div>
            <div className="col-3 chat_message">
              <small>
                <i>{date}</i>
              </small>
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="col-12" style={{ marginTop: 10 }}>
          <div className="t-line chatter-box speech-bubble"  style={{display: 'flex' }}>
            <div className="col-9 chat_message">
              <strong>{chat.sender}</strong> {(chat.contentType !== 2) ? <p>{chat.message}</p> : <img src={chat.file} width={"100%"} />}
            </div>
            <div className="col-3 chat_message">
              <small>
                <i>{date}</i>
              </small>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <Navbar />
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Row className="chatroomContainer" style={{ marginLeft: 50, marginRight: 50, marginTop: 50 }}>
          <Col lg={3} className="specialists">
            <div className="header">
              <div className="img">
                <img src="/assets/img/logo1.png" width="70px" />
              </div>
              <p>
                {i18next.t("specializationchatroom")}
                <br />
                <small>{i18next.t("choosespecialty")}</small>
              </p>
            </div>
            {professionalAreas.map((professionalArea) =>
              buildProfessionalAreaEntry(professionalArea)
            )}
          </Col>
          <Col lg={3} className="chatselection">
            <Row className="chatGroupsContainer">
              <Col xs={10}>
                <h5 className="mychatheading">{i18next.t("Mychatgroups")}</h5>
              </Col>
              <Col xs={2} className="chatAreaButtonCol">
                <button title={i18next.t("creategroup")}
                  className="chatGroupButton"
                  onClick={() =>
                    setShowCreateGroupModal(true)}>
                  <HiOutlineUserGroup />
                </button>
              </Col>
              <Col>
                {chatGroups.map((chatGroup) =>
                  buildChatGroupEntry(chatGroup, lastGroupAccess.find(x => x.groupId === chatGroup.id)))
                }
              </Col>
            </Row>
            <Row className="privateChats">
              <Col xs={6}>
                <h5 className="mychatheading">{i18next.t("otherChats")}</h5>
              </Col>
              <Col xs={6}>
                <Container>
                  <div className="searchBox">
                    <input className="chatSearch" variant="outlined" type="text" placeholder={i18next.t("search")} value={searchedItem} onChange={handleSearch} />
                    <HiSearch className="searchIcon" />
                  </div>
                  {(searchedItem === "") ? "" : filteredDoctors.map((doctor) => buildDoctorEntry(doctor))}
                </Container>
              </Col>
              <Col xs={12}>
                {lastDoctorAccess.map((doctor) =>
                  buildDoctorEntry(doctor)
                )}

              </Col>
            </Row>
          </Col>
          <Col lg={6} style={{ height: "100%" }}>
            <Row className="chatArea">
              <Row className="chatAreaHeader">
                <Col xs={9}>
                  <h5 className="mychatheading">{activeChatroomName}</h5>
                </Col>
                <Col xs={3} className="chatAreaButtonCol">
                  {(activeChatroomType == "group" && activeGroup.invited == true) &&
                    <button className="chatAreaButton" onClick={() => setShowAcceptInvitationModal(true)}>
                      <FiEye />
                    </button>
                  }
                  {(activeChatroomType == "group" && activeGroup.owner) &&
                    <button className="chatAreaButton" onClick={() => setShowEditGroupModal(true)}>
                      <FiSettings />
                    </button>
                  }
                  {(activeChatroomType == "group") && <button className="chatAreaButton" onClick={() => setShowInviteDoctorsModal(true)}>
                    <div>
                      <RiMailAddLine />
                    </div>
                  </button>
                  }
                  <button className="chatAreaButton" onClick={() => setShowSearchModal(true)}>
                    <div>
                      <HiSearch />
                    </div>
                  </button>
                </Col>
              </Row>
              <Row className="chatEntryArea" id="chatEntryArea">
                {activeChatMessages.map((msg) => buildChatEntry(msg, userChatId))}

                {showEmojiPicker &&
                  <Col xs={12}>
                    <Picker disableSkinTonePicker={true} onEmojiClick={onEmojiClick}
                      pickerStyle={{ width: "100%" }}
                      preload={true} />
                  </Col>
                }

              </Row>
              <Row className="chatWriteArea">
                <Col xs={10} className="chatTextInputArea">
                  <input type="text"
                    className="chatTextInput"
                    placeholder={i18next.t("entermessagehere")}
                    value={textBoxValue}
                    onChange={(e) => setTextBoxValue(e.target.value)}
                    onKeyDown={(e) => keyDownEnterChat(e)} />
                  <img className="emoji-button"
                    src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                    onClick={() => toggleEmojiPicker()} />
                </Col>
                <Col xs={2} className="chatInputButtonArea">
                  <button title={i18next.t("send")}
                    className="chatGroupButton"
                    onClick={sendMessage}>
                    <div>
                      <FiSend />
                    </div>
                  </button>
                  <button title={i18next.t("addAttachment")}
                    className="chatGroupButton"
                    onClick={() => inputFile.current.click()}>
                    <div>
                      <TiAttachmentOutline />
                    </div>
                  </button>
                  <input
                    type="file"
                    ref={inputFile}
                    onChange={(e) =>
                      sendImage(e.target.files[0])}
                    style={{ display: 'none' }} />
                </Col>
              </Row>
            </Row>
          </Col>
          <Modal size="lg" show={showCreateGroupModal} onHide={() => setShowCreateGroupModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{i18next.t("createchatgroup")}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleCreateGroupSubmit} noValidate validated={createGroupValidated}>
              <Modal.Body>
                <Form.Group controlId="groupName">
                  <Form.Label>{i18next.t("groupname")}</Form.Label>
                  <Form.Control name="name" type="text" placeholder={i18next.t("groupname")} required />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="groupDesc">
                  <Form.Label>{i18next.t("groupdesc")}</Form.Label>
                  <Form.Control name="description" type="text" placeholder={i18next.t("description")} required />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">{i18next.t("creategroup")}</Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal size="lg" show={showAcceptInvitationModal} onHide={() => setShowAcceptInvitationModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Group Invitation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong>{i18next.t("youhavebeeninvitedtogroup")}</strong>
              <p>{activeGroup && activeGroup.name}</p>
              <p>{activeGroup && activeGroup.description}</p>
              <p>{i18next.t("groupinvitefrom")} {activeGroup && activeGroup.doctor}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => { acceptDeclineInvitationSubmit(activeGroup.inviteId, 1) }}>{i18next.t("accept")}</Button>
              <Button variant="danger" onClick={() => { acceptDeclineInvitationSubmit(activeGroup.inviteId, 2) }}>{i18next.t("decline")}</Button>
            </Modal.Footer>
          </Modal>

          <Modal size="lg" show={showEditGroupModal} onHide={() => setShowEditGroupModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{activeGroup && activeGroup.name}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleEditGroupSubmit} noValidate validated={editGroupValidated}>
              <Modal.Body>
                <Form.Group controlId="groupDesc">
                  <Form.Label>{i18next.t("groupdesc")}</Form.Label>
                  <Form.Control name="description" type="text" placeholder={i18next.t("description")} defaultValue={activeGroup && activeGroup.description} required />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">{i18next.t("updategroup")}</Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal size="lg" show={showInviteDoctorsModal} onHide={() => setShowInviteDoctorsModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{i18next.t("invitedoctors")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleInviteDoctorsSubmit} noValidate validated={inviteDoctorsValidated}>
                <Form.Group controlId="">
                  <Form.Label>{i18next.t("selectdoctor")}</Form.Label>
                  <Select
                    name="doctors"
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    isMulti="true"
                    options={invitableDoctors}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit">{i18next.t("sendinvite")}</Button>
              </Form>
              <br></br>
              <Form onSubmit={handleInviteExternalDoctorsSubmit} noValidate validated={inviteExternalDoctorsValidated}>
                <Form.Group>
                  <Form.Label>{i18next.t("inviteExternalDoctor")}</Form.Label>
                  <Form.Control name="externalEmail" type="text" placeholder={i18next.t("enterExternalEmail")} required />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit">{i18next.t("sendinviteToExternal")}</Button>
              </Form>
              <center>
                <h6>{i18next.t("groupmembers")}: {activeGroupMembers && activeGroupMembers.map((member) =>
                  (<p>{member}</p>)
                )}
                </h6>
              </center>
            </Modal.Body>
          </Modal>

          <Modal size="lg" show={showSearchModal} onHide={() => setShowSearchModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{i18next.t("search")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSearchSubmit} noValidate validated={searchValidated}>
                <Form.Group>
                  <Form.Label></Form.Label>
                  <Form.Control name="searchedWord" type="text" placeholder={i18next.t("Keyword")} required />
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit">{i18next.t("search")}</Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Row>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return { profileParam: state.product.profile };
};

export default connect(mapStateToProps)(Chatroom);