import React from 'react';

export default function Rating(props) {

    return (
        <React.Fragment>
            <div className="well">
                <h2>Rate this Article</h2>
                <ul className="list-inline">
                    <li>
                        <span className="glyphicon glyphicon-star" aria-hidden="true" />
                    </li>
                    <li>
                        <span className="glyphicon glyphicon-star" aria-hidden="true" />
                    </li>
                    <li>
                        <span className="glyphicon glyphicon-star" aria-hidden="true" />
                    </li>
                    <li>
                        <span className="glyphicon glyphicon-star" aria-hidden="true" />
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}