import React from "react";
import Button from '@material-ui/core/Button';
import i18next from "i18next";

const Success = () => {
  return (
    <div
      className="app  align-items-center jumbotron d-flex align-items-center"
      style={{
        backgroundImage: "url(/assets/img/doctors1.jpg)",
        backgroundSize: "cover"
      }}
    >
      <br></br>
      <br></br>
      <center>
        <h2 style={{
          textAlign: "center",
          fontSize: "35px",
          color: "#043cac"
        }}>{i18next.t("Congratulations! You have successfully subscribed to Digital Doctor House.")}</h2>
        <br></br>

        <Button style={{ backgroundColor: "#09327e", color: "#fff" }}
          onClick={(event) => (window.location.href = "./login")}>
          {i18next.t("Return to dashboard")}
        </Button>
      </center>

    </div>
  );
};

export default Success;
