import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function EventsCardsList({ events, searchString = '' }) {
    const { t } = useTranslation();
    const locale = localStorage.getItem('lang');
    const [dynamicHeight, setDynamicHeight] = useState("500px");
    const eventsListRef = useRef(null);

    const updateHeight = () => {
        if (eventsListRef.current) {
            const rect = eventsListRef.current.getBoundingClientRect();
            const remainingHeight = window.innerHeight - rect.top;
            setDynamicHeight(`${remainingHeight}px`);
        }
    };

    const eventRefs = useRef(events.reduce((acc, event) => {
        acc[event.id] = React.createRef();
        return acc;
    }, {}));

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(locale ? locale : "de", options);
    };

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    const displayDates = (event) => {
        let startDate = new Date(event.startDate);
        let endDate = new Date(event.endDate);
        let fromDate = new Date(event.from);
        let toDate = new Date(event.to);

        let start = isValidDate(startDate) ? startDate : (isValidDate(fromDate) ? fromDate : null);
        let end = isValidDate(endDate) ? endDate : (isValidDate(toDate) ? toDate : null);

        if (start && end) {
            return `${formatDate(start)} - ${formatDate(end)}`;
        }
        return null;
    };

    const highlightText = (text, searchString) => {
        if (!searchString.trim()) return text;

        // g = global i = case insensitive
        const regex = new RegExp(`(${searchString})`, 'gi');
        const parts = text.split(regex);

        return parts.map((part, index) =>
            regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
        );
    };

    const EventCard = ({ event, innerRef }) => {
        const dateDisplay = displayDates(event);

        return (
            <div ref={innerRef} className='card mb-3' style={{ borderColor: event.color }}>
                <div className='row g-0'>
                    <div className='col-md-4 d-flex flex-column justify-content-center align-items-center bg-light border-end' style={{ borderColor: event.color }}>
                        {dateDisplay && (
                            <>
                                <h4 className='card-date-month'>{formatDate(event.startDate).split(' ')[0]}</h4>
                                <h5 className='card-date-day'>{formatDate(event.startDate).split(' ')[1]} {formatDate(event.startDate).split(' ')[2]}</h5>
                            </>
                        )}
                    </div>
                    <div className='col-md-8'>
                        <div className='card-body'>
                            <h5 className='card-title'>{highlightText(event.titleShort || event.title, searchString)}</h5>

                            {dateDisplay && (
                                <p className='card-text'>
                                    <small className='text-muted'>{highlightText(dateDisplay, searchString)}</small>
                                </p>
                            )}

                            {event.description && (
                                <p className='card-text'>{highlightText(event.description, searchString)}</p>
                            )}

                            {event.location && (
                                <p className='card-text'>
                                    <small className='text-muted'>{t("Location")}: {highlightText(event.location, searchString)}</small>
                                </p>
                            )}

                            {event.registrationLink && (
                                <a className='card-text' href={event.registrationLink} hrefLang={locale}>{t("register")}</a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    useEffect(() => {
        const now = new Date();
        let closestEventId = null;
        let minimumTimeDifference = Infinity;

        events.forEach(event => {
            const timeDifference = Math.abs(new Date(event.startDate) - now);
            if (timeDifference < minimumTimeDifference) {
                minimumTimeDifference = timeDifference;
                closestEventId = event.id;
            }
        });

        if (closestEventId && eventRefs.current[closestEventId]) {
            eventRefs.current[closestEventId].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [events]);

    return (
        <div ref={eventsListRef} style={{ maxHeight: dynamicHeight, overflowY: 'auto' }}>
            {events.map((event) => (
                <EventCard key={event.id} event={event} innerRef={eventRefs.current[event.id]} />
            ))}
        </div>
    );
};
