import React from "react";
import { connect } from "react-redux";
import Rating from "./Rating";
import SearchBar from "../search/search-bar";

function Author(props) {
  const author = props.author;
  return (
    <React.Fragment>
      <div className="well">
        <h2>About Author</h2>
        <img
          src=""
          className="img-rounded"
          width="auto"
        />
        {/* { author.image } */}
        <p>{author.description}</p>
        <a href={author.url} className="btn btn-primary1">
          Read more
        </a>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  author: state.article.article.author
});

export default connect(mapStateToProps)(Author);
