import React from "react";
import queryString from "query-string";
import "../dashboard.css";
import { connect } from "react-redux";
import SearchResult from "./search-results";
import { results } from "../../../_actions/data/result";
import Select from "react-select";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import SearchBar from "./search-bar";
import { fetchProducts, searchProduct } from "../../../_actions/search.action";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "reactstrap";
import containingResults from "./containing-results";
import NavBar from "../navBar";
import Footer from "../footer";

class SearchDisplay extends React.Component {
  componentDidMount() {
    /*const query = queryString.parse(this.props.location.search).q;
    console.log(query);
    console.log("Location Props", this.props.location.search);

    this.props.searchProduct(query);
    this.props.fetchProducts(query);*/
    var searchData=localStorage.getItem("searchData");

    console.log("search",searchData);
    this.props.searchProduct(searchData);
    this.props.fetchProducts(searchData);
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    // if (nextProps.query !== this.props.query) {
    //   this.setState({ query: nextProps.query });
    //   return this.searchRepositories(nextProps.query);
    // }
  }

  render() {
    const { loading } = this.props;
    const str = this.props.location.search;
    const res = str.split("?q=");
    console.log("PROS", this.props);

    // const companies = [
    //   {
    //     label: "Embryotox",
    //     value: "https://www.embryotox.de"
    //   },
    //   {
    //     label: "Apple",
    //     value: "https://www.apple.com"
    //   },
    //   {
    //     label: "DDH",
    //     value: "https://www.ddh.com"
    //   }
    // ];
    // const Order = [
    //   {
    //     label: "A-Z"
    //   },
    //   {
    //     label: "Z-A"
    //   }
    // ];
    return loading ? (
      <div class="row"></div>
    ) : (
      <React.Fragment>
        <NavBar></NavBar>
        <SearchBar/>
        <div className="row">
          <div className="col-sm-8 col-md-6 offset-sm-2 offset-md-3">
            <div class="row">
              <div className="col-10 offset-1" />
                <SearchResult />
              </div>
            </div>

        </div>
        <div className="row nopadding nomargin">
              <div className="col-sm-12 col-md-12 nopadding">
                <Footer/>
              </div>
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.product.loading
});

export default connect(mapStateToProps, { searchProduct, fetchProducts })(
  SearchDisplay
);
