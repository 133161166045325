import {
  SEARCH_PRODUCTS,
  FETCH_PRODUCTS,
  LOADING,
  LOADER,
  PAGINATE,
  PRODUCT_DETAILS,
  USER_PROFILE,
  KEYWORD,
  FILTERED_PRODUCTS
} from "../_actions/types";

const initialState = {
  text: "",
  products: [],
  loading: false,
  loader:true,
  product: [],
  profile: [],
  filter: [],

  // product: {
  //   title: "Aspirine",
  //   image: "/assets/img/drug1.jpg",
  //   company: "Abbott",
  //   rating: 3,
  //   totalReviews: 2,
  //   deliveryTime: "7 Working Days",
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the.",
  //   contact: {
  //     name: "John Doe",
  //     title: "CEO & Founder, Example",
  //     organization: "Harvard University",
  //     social: {
  //       drible: "a",
  //       twitter: "b",
  //       linkedin: "c",
  //       facebook: "d"
  //     }
  //   }
  // },

  pagination: {
    totalRecords: 0,
    totalPages: 0,
    pageLimit: 4,
    currentPage: 1,
    startIndex: 1,
    endIndex: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTERED_PRODUCTS:
      console.log(
        "action -- Filtered Pro",
        action,
        "state -- Filtered Pro ",
        state
      );
      return {
        ...state,
        filter: action.payload
      };

    case SEARCH_PRODUCTS:
      return {
        ...state,
        text: action.payload,
        loading: false,
      };

    case PAGINATE:
      return {
        ...state,
        pagination: { ...state.pagination, currentPage: action.payload }
      };

    case FETCH_PRODUCTS:
      // console.log(
      //   "action -- FetchProducts",
      //   action,
      //   "state -- FetchPro",
      //   state
      // );
      return {
        ...state,
        products: action.payload,
        loading: false,
        loader:false,
        totalRecords: action.payload.length
      };

    case LOADING:
      return {
        ...state,
        loading: true
      };
    case LOADER:
      return {
        ...state,
        loader: true
      };

    case PRODUCT_DETAILS:
      // console.log("Product Details -- Action", action);
      // console.log("Product Details -- payload", action.payload);
      // console.log("Product Details -- state", state);

      return {
        ...state,
        product: action.payload,
        loading: false,
        totalRecords: action.payload.length
      };

    case USER_PROFILE:
      // console.log("USER_PROFILE -- Action", action);
      // console.log("USER_PROFILE -- payload", action.payload);
      // console.log("USER_PROFILE -- state", state);
      return {
        ...state,
        profile: action.payload
      };

    case KEYWORD:
      console.log("KEYWORD -- Action", action);
      console.log("KEYWORD-- Payload", action.payload);

      return {
        ...state,
        text: action.payload
      };

    default:
      // console.log("Default action");
      return state;
  }
};
