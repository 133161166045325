import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { LogoutLocation } from "../../locations";
import Navbar from "./navBar";
import Footer from "./footer";
import Dashboard from "../../views/Default/Dashboard/Dashboard";
import "../dashboard/search/test.css";
import { productService } from "../../_services/product.service";
import { messagesService } from "../../_services/messages.service";
import { cogoToastHelper } from "../../_helpers/cogoToastHelper";
import Accordion from "react-bootstrap/Accordion";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import { profile } from "../../_actions/profile.action";
import { userService } from "../../_services/user.service";
import { connect } from "react-redux";
import SearchBar from "./search/search-bar";
import * as signalR from "@microsoft/signalr";
import { messageHubUrl } from "../../config.example";
import Loader from "react-loader-spinner";
import { animateScroll } from "react-scroll";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  FormGroup,
  FormFeedback,
  Input,
} from "reactstrap";
// import "../dashboard/search/test.css";
import { companyService } from "../../_services/company.service";
import i18next from "i18next";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      msg: "",
      addedDate: "",
      user_Id_Sender: "",
      toggle: false,
      message: "",
      conversations: [],
      receiver: "",
      product_id: "",
      current_product: {},
      messagesList: [],
      currentConversation: null,
      oldConversations: [],
      search: "",
      Files: [],
      selectedFile: null,
      hubConnection: null,
      loadingMessages: false,
      newmessage: false,
      Companies: [],
      CompaniesID: [],
      new__message: "",
      newmessagecompany: "",
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      suggestions: [],
      newmessagesubject:""
    };
  }

  componentDidMount() {

  }


  render() {
    console.log("Profile props", this.props.profile);
    let props = this.props.profile;

    return (
      <React.Fragment>
        <Navbar />
        <SearchBar />
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row inboxContainer">
              <div className="col-lg-3 col-md-4 col-sm-4 r-line nopaddings messageGroup">
                <div style={{padding:'20px 20px 20px 20px'}}>{i18next.t("supports")}<br/><br/>
                <b>{i18next.t( "alternativecontacts")}</b><br/><br/>
                <b>{i18next.t("phone")}: +41 41 501 49 73</b><br/>
                <b>E-mail: <a href="mailto:support@digital-doctor-house.com">support@digital-doctor-house.com</a></b></div>


                <div className="">
                {this.state.conversations.map((conversation, index) => {
                  return (
                    <div className="row mail nopaddings b-line">
                      <div
                        className="col-12 nopaddings"
                        onClick={() => this.readMessage(conversation)}
                      >
                        <div className="row nopaddings">
                          <div className="col-2 nopaddings cursor">
                            <div className="circle">
                              {conversation.user_Reciever_Name.substring(0, 1)}
                            </div>
                          </div>
                          <div className="col-10 nopaddings cursor">
                            <div className="row nopaddings">
                              <div className="col-8 nopaddings">
                                <p className="mailName mheader">
                                  {conversation.user_Reciever_Name}
                                </p>
                              </div>
                              <div className="col-4 nopaddings r-align">
                                <p className="mailName mheader">
                                  {this.formateDate(conversation.modifiedDate)}
                                </p>
                              </div>
                              <div className="col-12 nopaddings mheader">
                                <b className="subject">
                                  {conversation.productName}
                                </b>
                                <p className="messageContent">
                                  {conversation.subject}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-12 inboxMsg nopaddings">
                <h3 style={{padding:20}}>{i18next.t("sendmsg")}<br/>
                <small style={{fontSize:12}}>{i18next.t("sendmsgsub")}</small></h3>
                {this.state.loadingMessages && (
                  <center>
                    <Loader type="ThreeDots" color="#00BFFF"></Loader>
                  </center>
                )}
                <div className="new_messages">
                    <Formik
                      initialValues={{
                        message: this.state.new__message,
                        comapany: this.state.newmessagecompany,
                        newmessagesubject:this.state.newmessagesubject
                      }}
                      validationSchema={Yup.object().shape({})}
                      onSubmit={(data, actions) => {
                        //body
                        let contentType = 0;
                        if (this.state.selectedFile !== null) contentType = 1;

                        if (
                          this.state.selectedFile !== null &&
                          this.state.new__message !== ""
                        )
                          contentType = 5;

                          console.log(data);
                          let formData={
                            "Text":data.message,
                            "Subject":data.subject,
                            "User_id_Sender":userService.getUserId(),
                          }

                          this.setState({loadingMessages:true});
                          messagesService.support(formData)
                          .then(
                            response => {
                              actions.resetForm();
                              cogoToastHelper.success(response);
                              actions.setSubmitting(false);
                              this.setState({loadingMessages:false});
                            },
                            error => {
                              actions.setSubmitting(false);
                              cogoToastHelper.error((error.toString()));
                              this.setState({loadingMessages:false});
                            }
                          );
                      }}
                      render={({
                        values,
                        errors,
                        status,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form
                          action=""
                          method="post"
                          className="newmessageForm"
                        >

                          <FormGroup>
                            <Input
                              id="subject"
                              name="subject"
                              placeholder={i18next.t("subject")}
                              tag={Field}
                              invalid={
                                errors.subject && touched.subject
                              }
                              className="newmessageFormInput"
                              valid={this.state.subject}
                            />
                            <FormFeedback>{errors.subject}</FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="textarea"
                              id="message"
                              name="message"
                              tag={Field}
                              placeholder={i18next.t("entermessage")}
                              invalid={
                                errors.message && touched.message
                              }
                              valid={this.state.message}
                            />
                            <FormFeedback>{errors.message}</FormFeedback>
                          </FormGroup>
                          {!isSubmitting && (
                            <Col sm="12" md="12" style={{ padding: 0 }}>
                              <Button
                                className="cardButton sendReply"
                                block
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {i18next.t("sendreply")}
                              </Button>
                            </Col>
                          )}
                          {isSubmitting && <Spinner color="success" />}
                        </Form>
                      )}
                    />
                  </div>

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.product.profile,
  // product: state.product.id
});

export default connect(mapStateToProps)(Profile);
// export default connect(null, { profile })(Profile);
