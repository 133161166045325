import React from 'react';
import { connect } from 'react-redux';

function Header(props) {
    const article = props.article;
    return (
        <React.Fragment>
            <h1 className="text-center">{article.title}</h1>
            <ul className="list-inline text-center">
                <li>{article.author.name} |</li>
                <li>{article.category} |</li>
                <li>{article.publicationDate} |</li>
            </ul>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    article: state.article.article
});

export default connect(mapStateToProps)(Header);