import axios from "axios";
import { API } from "../urls";

export const sourcesService = {
  getSources,
  NewSource,
  getSubscriptions,
  getSwissPedDoseDetails,
  ApproveSource,
  UnSubscribeSource,
  sourceCheck,
  subscribe,
  subscriptioncomment,
  getComments,
  tracker,
  professions,
  professionalArea,
  sourceupdate,
  updateSource,
};

function professionalArea(){
  return axios.get(API.professionalAreaLocation.toUrl());
}
function professions(){
  return axios.get(API.professionsLocation.toUrl());
}
function subscriptioncomment(data){
  return axios.post(API.subscriptioncomment.toUrl(),data);
}
function sourceupdate(data){
  return axios.post(API.sourceupdate.toUrl(),data);
}
function subscribe(data){
  return axios.post(API.subscribe.toUrl(),data);
}
function getSources() {
  return axios.get(API.sourcesLocation.toUrl());
}
async function getSwissPedDoseDetails (id) {
  return await axios.get(API.swissPedDoseDetailsLocation.toUrl({
    id: id
  }));
}
function getComments() {
  return axios.get(API.getComments.toUrl());
}
function sourceCheck(data){
  return axios.post(API.sourceCheckLocation.toUrl(),data);
}
function NewSource(data) {
  return axios.post(API.sourcesLocation.toUrl(), data);
}
function getSubscriptions(data) {
  return axios.get(API.suscriptionsLocation.toUrl());
}
function tracker(id) {
  console.log("id::",id);
  return axios.get(id)
}
function ApproveSource(id) {
    let url = API.approveSourceLocation.toUrl({ id });
    return axios.put(url)
}

function UnSubscribeSource(data) {
  return axios.post(API.unsubscribeLocation.toUrl(), data)
}

function updateSource(id) {
  let url = API.updateSourceLocation.toUrl({ id });
  return axios.post(url)
}

