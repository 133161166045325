import axios from "axios";
import { API } from "../urls";

export const trackingServices = {
    insertUserInteraction,
    getTrackedUserInteractionsByUserId,
    getTrackedUserInteractionsByProductId,
    getAllTrackedUserInteractionsFromType,
    getAllTrackedUserInteractions
};

function insertUserInteraction(data) {
    return axios.post(API.setUserInteractionTrackingLocation.toUrl(), data);
}

function getTrackedUserInteractionsByUserId(id) {
    return axios.get(API.getTrackedUserInteractionsByUserIdLocation.toUrl() + "/" + id);
}

function getTrackedUserInteractionsByProductId(id) {
    return axios.get(API.getTrackedUserInteractionsByProductIdLocation.toUrl() + "/" + id);
}

function getAllTrackedUserInteractionsFromType(type) {
    return axios.get(API.getAllTrackedUserInteractionsFromTypeLocation.toUrl() + "/" + type);
}

function getAllTrackedUserInteractions() {
    return axios.get(API.getAllTrackedUserInteractionsLocation.toUrl());
}