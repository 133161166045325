import React from "react";

export default function Media(props) {
  return (
    <React.Fragment>
      <div className="well">
        <div className="media">
          <div className="media-left">
            <a href="#">
              <img
                data-src="holder.js/64x64"
                className="media-object"
                alt="64x64"
                style={{ width: "64px", height: "64px" }}
                src="https://arssoftwaresolutions.de/wp-content/uploads/2019/08/logo.png"
                data-holder-rendered="true"
              />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading">Media heading</h4>
            {/* Cras sit amet nibh libero, in gravida nulla. */}
          </div>
        </div>
        <div className="media">
          <div className="media-left">
            <a href="#">
              <img
                data-src="holder.js/64x64"
                className="media-object"
                alt="64x64"
                style={{ width: "64px", height: "64px" }}
                src="https://arssoftwaresolutions.de/wp-content/uploads/2019/08/logo.png"
                data-holder-rendered="true"
              />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading">Media heading</h4>
            {/* Cras sit amet nibh libero, in gravida nulla. */}
          </div>
        </div>
        <div className="media">
          <div className="media-left">
            <a href="#">
              <img
                data-src="holder.js/64x64"
                className="media-object"
                alt="64x64"
                style={{ width: "64px", height: "64px" }}
                src="https://arssoftwaresolutions.de/wp-content/uploads/2019/08/logo.png"
                data-holder-rendered="true"
              />
            </a>
          </div>
          <div className="media-body">
            <h4 className="media-heading">Media heading</h4>
            {/* Cras sit amet nibh libero, in gravida nulla. */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
