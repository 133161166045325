import axios from "axios";
import { API } from "../urls";

export const voucherServices = {
  getAllBatches,
  createBatch,
  getAllVouchers,
  Sponsor,
  Sponsor1,
};


function getAllBatches(id) {
  return axios.get(API.companyBatchLocation.toUrl({id}));
}

function getAllVouchers(id) {
  return axios.get(API.companyVouchersLocation.toUrl({id}));
}

function createBatch(data) {
  return axios.post(API.companyNewBatchLocation.toUrl(), data);
}

function Sponsor(id){
  return axios.get(API.companySponsor.toUrl({id}));
}

function Sponsor1(){
  return axios.get(API.companySponsor1.toUrl());
}

