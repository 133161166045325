import React from 'react';

export default function Subscription(props) {

    return (
        <React.Fragment>
            <div className="well">
                <h2>Subscription Box</h2>
                <p>Form Description Goes here</p>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search for..." />
                    <span className="input-group-btn">
                        <button className="btn btn-primary1" type="button">Go!</button>
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
}