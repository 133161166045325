import React, {Component} from 'react';
import {Bar, Line} from 'react-chartjs-2';

import {ButtonDropdown, ButtonGroup, Card, CardBody, Col, Dropdown, Row, Spinner} from 'reactstrap';
import {CustomTooltips} from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import {getStyle} from '@coreui/coreui/dist/js/coreui-utilities'
import i18next from 'i18next';
import {userService} from "../../../_services/user.service.js";
import DoctorBranchesChart from '../../Admin/Doctors/DoctorBranchesChart.js';


const brandPrimary = getStyle('--primary')
const brandInfo = getStyle('--info')

// Card Chart 1
const cardChartData1 = {
  bels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: brandPrimary,
      borderColor: 'rgba(255,255,255,.55)',
      data: [65, 59, 84, 84, 51, 55, 40],
    },
  ],
};

const cardChartOpts1 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent',
        },
        ticks: {
          fontSize: 2,
          fontColor: 'transparent',
        },

      }],
    yAxes: [
      {
        display: false,
        ticks: {
          display: false,
          min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
          max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
        },
      }],
  },
  elements: {
    line: {
      borderWidth: 1,
    },
    point: {
      radius: 4,
      hitRadius: 10,
      hoverRadius: 4,
    },
  }
}


// Card Chart 2
const cardChartData2 = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: brandInfo,
      borderColor: 'rgba(255,255,255,.55)',
      data: [1, 18, 9, 17, 34, 22, 11],
    },
  ],
};

const cardChartOpts2 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent',
        },
        ticks: {
          fontSize: 2,
          fontColor: 'transparent',
        },

      }],
    yAxes: [
      {
        display: false,
        ticks: {
          display: false,
          min: Math.min.apply(Math, cardChartData2.datasets[0].data) - 5,
          max: Math.max.apply(Math, cardChartData2.datasets[0].data) + 5,
        },
      }],
  },
  elements: {
    line: {
      tension: 0.00001,
      borderWidth: 1,
    },
    point: {
      radius: 4,
      hitRadius: 10,
      hoverRadius: 4,
    },
  },
};

// Card Chart 3
const cardChartData3 = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(255,255,255,.2)',
      borderColor: 'rgba(255,255,255,.55)',
      data: [78, 81, 80, 45, 34, 12, 40],
    },
  ],
};

const cardChartOpts3 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: false,
      }],
    yAxes: [
      {
        display: false,
      }],
  },
  elements: {
    line: {
      borderWidth: 2,
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
    },
  },
};

// Card Chart 4
const cardChartData4 = {
  labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(255,255,255,.3)',
      borderColor: 'transparent',
      data: [78, 81, 80, 45, 34, 12, 40, 75, 34, 89, 32, 68, 54, 72, 18, 98],
    },
  ],
};

const cardChartOpts4 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: false,
        barPercentage: 0.6,
      }],
    yAxes: [
      {
        display: false,
      }],
  },
};


// Main Chart

//Random Numbers
function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

var elements = 27;
var data1 = [];
var data2 = [];
var data3 = [];

for (var i = 0; i <= elements; i++) {
  data1.push(random(50, 200));
  data2.push(random(80, 100));
  data3.push(65);
}


class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

    this.state = {
      dropdownOpen: false,
      radioSelected: 2,
      stats: {},
      isSuperAdmin: userService.isSuperAdmin(),
    };
  }

  componentDidMount() {
    this.getStats();
    const lang = localStorage.getItem('lang') || 'en';
    userService.culture(lang);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onRadioBtnClick(radioSelected) {
    this.setState({
      radioSelected: radioSelected,
    });
  }

  getStats() {
    userService.getStats()
      .then(stats => {
        console.log("stats", stats[0]);
        if (stats !== null)
          this.setState({stats: stats[0]});
      })
  }

  loading = () => <Spinner color="success"/>

  render() {
    const {stats} = this.state;
    console.log(stats);
    return (

      <div className="animated fadeIn">

        <Row>
          {
            (userService.checkSuperAdmin() === "true") &&
            <Col xs="12" sm="6" lg="2">
              <Card className="text-white bg-info">
                <CardBody className="pb-0">
                  <ButtonGroup className="float-right">
                    <ButtonDropdown id='card1' isOpen={this.state.card1} toggle={() => {
                      this.setState({card1: !this.state.card1});
                    }}>

                    </ButtonDropdown>
                  </ButtonGroup>
                  <a href="/dashboard/doctors" className="txt-white">
                    <div className="text-value">{stats.doctors}</div>
                    <div>{i18next.t("doctors")}</div>
                  </a>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                  <Line data={cardChartData2} options={cardChartOpts2} height={70}/>
                </div>
              </Card>
            </Col>
          }
          {(userService.checkSuperAdmin() === "true") &&
            <Col xs="12" sm="6" lg="2">
              <Card className="text-white bg-primary">
                <CardBody className="pb-0">
                  <ButtonGroup className="float-right">
                    <Dropdown id='card2' isOpen={this.state.card2} toggle={() => {
                      this.setState({card2: !this.state.card2});
                    }}>
                      {/* <DropdownToggle className="p-0" color="transparent">
                      <i className="icon-location-pin"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                    </DropdownMenu> */}
                    </Dropdown>
                  </ButtonGroup>
                  <a href="/dashboard/companies" className="txt-white">
                    <div className="text-value">{stats.companies}</div>
                    <div>{i18next.t("companies")}</div>
                  </a>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                  <Line data={cardChartData1} options={cardChartOpts1} height={70}/>
                </div>
              </Card>
            </Col>
          }
          <Col xs="12" sm="6" lg="2">
            <Card className="text-white bg-danger">
              <CardBody className="pb-0">
                <ButtonGroup className="float-right">
                  <ButtonDropdown id='card4' isOpen={this.state.card4} toggle={() => {
                    this.setState({card4: !this.state.card4});
                  }}>

                  </ButtonDropdown>
                </ButtonGroup>
                <a href="/dashboard/products" className="txt-white">
                  <div className="text-value">{stats.products}</div>
                  <div>{i18next.t("products")}</div>
                </a>
              </CardBody>
              <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                <Bar data={cardChartData4} options={cardChartOpts4} height={70}/>
              </div>
            </Card>
          </Col>
          {(userService.checkSuperAdmin() === "true") &&
            <Col xs="12" sm="6" lg="2">
              <Card className="text-white bg-primary">
                <CardBody className="pb-0">
                  <ButtonGroup className="float-right">
                    <Dropdown id='card2' isOpen={this.state.card2} toggle={() => {
                      this.setState({card2: !this.state.card2});
                    }}>

                    </Dropdown>
                  </ButtonGroup>
                  <a href="/dashboard/sources" className="txt-white">
                    <div className="text-value">{stats.sources}</div>
                    <div>{i18next.t("sources1")}</div>
                  </a>
                </CardBody>
                <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                  <Line data={cardChartData1} options={cardChartOpts1} height={70}/>
                </div>
              </Card>
            </Col>
          }
          {(userService.checkSuperAdmin() === "true") &&
            <>
              <Col xs="12" sm="6" lg="2">
                <Card className="text-white bg-warning">
                  <CardBody className="pb-0">
                    <ButtonGroup className="float-right">
                      <Dropdown id='card3' isOpen={this.state.card3} toggle={() => {
                        this.setState({card3: !this.state.card3});
                      }}>

                      </Dropdown>
                    </ButtonGroup>
                    <a href="/dashboard/events" className="txt-white">
                      <div className="text-value">{stats.events}</div>
                      <div>{i18next.t("events")}</div>
                    </a>
                  </CardBody>
                  <div className="chart-wrapper" style={{height: '70px'}}>
                    <Line data={cardChartData3} options={cardChartOpts3} height={70}/>
                  </div>
                </Card>
              </Col>
              <Col xs="12" sm="6" lg="2">
                <Card className="text-white bg-warning">
                  <CardBody className="pb-0">
                    <ButtonGroup className="float-right">
                      <Dropdown id='card3' isOpen={this.state.card3} toggle={() => {
                        this.setState({card3: !this.state.card3});
                      }}>

                      </Dropdown>
                    </ButtonGroup>
                    <a href="/dashboard/doctornetworks" className="txt-white">
                      <div className="text-value">{stats.doctornetworks}</div>
                      <div>{i18next.t("Doctor networks")}</div>
                    </a>
                  </CardBody>
                  <div className="chart-wrapper" style={{height: '70px'}}>
                    <Line data={cardChartData3} options={cardChartOpts3} height={70}/>
                  </div>
                </Card>
              </Col>
            </>
          }
        </Row>
        {(userService.checkSuperAdmin() === "true") &&
          <DoctorBranchesChart/>
        }
      </div>
    );
  }
}

export default Dashboard;
