import * as Yup from "yup";

import Location from "react-app-location";

export const HomeLocation = new Location("/");
export const LoginLocation = new Location("/login");
export const LogoutLocation = new Location("/logout");
// export const RegistrationLocation = new Location('/register');

export const NotfoundLocation = new Location("/404");
export const ServerErrorLocation = new Location("/500");

export const DashboardLocation = new Location("/dashboard");

export const GroupListLocation = new Location("/dashboard/groups");
export const NewGroupLocation = new Location("/dashboard/groups/new");
export const GroupLocation = new Location("/dashboard/groups/:id", {
  id: Yup.string()
});
export const GroupRolesLocation = new Location("/dashboard/groups/:id/roles", {
  id: Yup.string()
});

export const CategoryListLocation = new Location("/dashboard/categories");
export const NewCategoryLocation = new Location("/dashboard/categories/new");
export const CategoryLocation = new Location("/dashboard/categories/:id", {
  id: Yup.number()
});


export const RequestedSourceListLocation = new Location("/dashboard/requestedsources");
export const SourcesListLocation = new Location("/dashboard/sources");
export const NewSourceLocation = new Location("/dashboard/sources/new");

export const RoleListLocation = new Location("/dashboard/roles");
export const NewRoleLocation = new Location("/dashboard/roles/new");
export const RoleLocation = new Location("/dashboard/roles/:id", {
  id: Yup.string()
});

//Voucher end points
export const BatchListLocation = new Location("/dashboard/batch");
export const VouchersListLocation = new Location("/dashboard/vouchers/:id", {id: Yup.string()});
export const NewBatchLocation = new Location("/dashboard/vouchers/new");

export const DoctorListLocation = new Location("/dashboard/doctors");
export const DoctorBranchesChartLocation = new Location("/dashboard/doctorBranchesChart");
export const NewDoctorLocation = new Location("/dashboard/doctors/new");
export const DoctorLocation = new Location("/dashboard/doctors/:id", {
  id: Yup.string()
});

export const CompanyListLocation = new Location("/dashboard/companies");
export const NewCompanyLocation = new Location("/dashboard/companies/new");
export const CompanyLocation = new Location("/dashboard/companies/:id", {
  id: Yup.string()
});

export const ProductPreviewLocation = new Location("/dashboard/preview/:id", {
  id: Yup.string()
});
export const ProductUserInteractionListLocation = new Location("/dashboard/productUserInteractions");
export const ProductListLocation = new Location("/dashboard/products");
export const NewProductLocation = new Location("/dashboard/products/new");
export const ProductLocation = new Location("/dashboard/products/:id", {
  id: Yup.string()
});
export const ProductViewLocation = new Location(
  "/dashboard/products/:id/view",
  { id: Yup.string() }
);
export const ProductHotlineLocation = new Location("/dashboard/company/producthotline");
export const NewProductHotlineLocation = new Location("/dashboard/company/producthotline/new");
export const EditProductHotlineLocation = new Location("/dashboard/producthotline/:id", {
  id: Yup.string()
});

export const SubUserListLocation = new Location("/dashboard/company/subusers");
export const MessagesListLocation = new Location("/dashboard/company/messages");
export const NewSubUserLocation = new Location(
  "/dashboard/company/subuser/new"
);
export const SubUserLocation = new Location("/dashboard/company/subusers/:id", {
  id: Yup.string()
});

export const DoctorHomeLocation = new Location("/dashboard");
export const DoctorProductLocation = new Location("/dashboard/product/details");
export const DoctorArticleLocation = new Location("/dashboard/article");

export const resultLoader = new Location("/resultLoader?q=:id",{
  id: Yup.string()
});

export const DoctorNetworkLocation = new Location("/dashboard/doctornetworks");
export const NewDoctorNetworkLocation = new Location("/dashboard/doctornetworks/new");
export const EditDoctorNetworkLocation = new Location("/dashboard/doctornetworks/:id", {
  id: Yup.string()
});

export const ProductDetails = new Location("/dashboard/productDetails");

export const Profile = new Location("/dashboard/profile");

export const EventLocation = new Location("/dashboard/events");
export const NewEventLocation = new Location("/dashboard/events/new");
export const EventDetailLocation = new Location("/dashboard/events/:id", {
  id: Yup.string()
});
export const searchDisplay = new Location("/searchDisplay?q=:id",{
  id: Yup.string()
});

export const article = new Location("/readArticle");

export const GptLocation = new Location("/gpt");