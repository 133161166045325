import React, { Component } from "react";
import {
  Nav,
  NavLink,
  NavItem,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Spinner,
  FormGroup,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import ProductTabs from "./ProductTabs";
import Rating from "./Rating";
import "../../../website/dashboard/dashboard.css";
import "../../../website/dashboard/search/test.css";
import SearchBar from "../search/search-bar";
import Navbar from "../../../website/dashboard/navBar";
import { productService } from "../../../_services/product.service";
import { messagesService } from "../../../_services/messages.service";
import { companyService } from "../../../_services/company.service";
import { userService } from "../../../_services/user.service";
import Loader from "react-loader-spinner";
import { Form, Field, Formik } from "formik";
import {cogoToastHelper} from '../../../_helpers/cogoToastHelper';
import * as Yup from "yup";
import {messageHubUrl} from '../../../config.example';
import * as signalR from "@microsoft/signalr";
import i18next from "i18next";


class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle:false,
      images:'',
      subject:'',
      activeTab: "1",
      activeLang: "en",
      companyName:'',
      companyEmail:'',
      companyLogo:'',
      message:'',
      receiver:'',
      imagesLoading:true,
      message: '',
      messages: [],
      hubConnection: null,
      callforImage:true,
      product:null
    };
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    if(this.state.product===null){
      this.setState({product:this.props.product});
      this.getProductImage(this.props.product.id, this.state.activeLang);

      if(this.props.product.modifiedBy!==null){
        this.getCompanyDetails(this.props.product.modifiedBy.id);
      }
    }
  }

  getProductImage(id, langCode){
    console.log("called here::", id);
    productService.getProductImagesLang(id, langCode).then(images => {
      if(images){
        if(typeof(images)=='object'){
          if(images.length>0){
            this.setState({images:images[0].baseUri});
          }
        }
      }
      this.setState({callforImage:false});
      this.setState({imagesLoading:false});
    });
  }

  getCompanyDetails(id){
    console.log("Company details===",id);
    companyService.getCompany(id).then(company => {
      console.log("Company details ",company,id);
      if(company!==null){
        this.setState({
          companyName:company.company,
          companyEmail:company.email,
          companyLogo:company.logo,
          receiver:id
        });
      }
    });
  }

  toggleProps(){
    console.log(this.state.toggle);
    if(this.state.toggle===true){
      this.setState({toggle:false});
    }
    if(this.state.toggle===false){
      this.setState({toggle:true});
    }
  }

  onChange(data){
    this.setState({message:data});
  }

  onChangeSubject(data){
    this.setState({subject:data});
  }

  toggleLang = lang => {
    if (this.state.activeLang !== lang) {
      this.setState({
        activeLang: lang
      });
    }
  };

  render() {
    const product = this.props.product;
    console.log("--------------------",product);
    console.log("------>>",product.logo);
  return (

    <React.Fragment>
      <Navbar></Navbar>
      <div className="row nopadding nomargin">
          <div className="col-sm-8 col-md-8 offset-sm-2 offset-md-2">
            <SearchBar/>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="row">
            <div className="col-md-9 col-sm-12">
            <div
            className="col-sm-12 col-md-12 col-lg-12"
            style={{ paddingBottom: "50px" }}>
            {!product.id && <center><Loader type="ThreeDots" color="#00BFFF"></Loader></center>}
            <div className="product-content product-wrap clearfix product-detail">
              <div className="row">
                <div className="col-md-5 col-sm-12 col-xs-12 ">
                  <div className="product-image">
                    <div id="myCarousel-2" className="carousel">
                      <div className="carousel-inner">
                        <div className="item active">
                          {" "}
                          {(this.state.imagesLoading===true) && <center>{this.state.callforImage}<Loader type="ThreeDots" color="#00BFFF"></Loader></center>}
                          {(this.state.imagesLoading===false) && <img src={this.state.images} alt="" className="searchImage"/>}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 col-xs-12">
                <br/>
                  <h2 className="name">
                    {" "}
                    {product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].brandName : product.brandName}
                    {"  "}
                    <small>
                      <a>{product.company}</a>
                    </small>{" "}
                    {product.company}
                    <Rating rating={product.rating}></Rating>
                    <br />
                    <span className="fa fa-1x">
                      <h3>
                        {product.totalReviews}
                        {product.totalReviews > 1 ? "s" : ""}
                      </h3>
                    </span>
                  </h2>

                  {/* <hr /> */}

                  {/* <div className="certified">
                    <ul>
                      <li>Delivery time {product.deliveryTime}</li>
                      <li>Certified Quality Assured</li>
                    </ul>
                  </div> */}
                  <p>{product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].genericName : product.genericName}</p>
                  <hr />
                  <div>
                    <p><div dangerouslySetInnerHTML={{__html: product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].indications : product.indications}} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="card companyCard" style={{padding:'20px 10px'}}>
              {(product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].logo : product.logo!==null)  && <img src={product.languages && product.languages[this.state.activeLang] ? product.languages[this.state.activeLang].logo : product.logo} alt={this.state.companyName} className="fullWidth"  style={{paddingBottom:10}}/>}

              {(this.state.companyLogo===null)  && <div><br/><br/></div>}
              {(this.state.companyLogo!==null)  && <img src={this.state.companyLogo} alt={this.state.companyName} className="fullWidth"  style={{paddingBottom:10}}/>}
              {(this.state.receiver!='') && <p className="noBottomMargin"><Button className="cardButton" onClick={() => this.toggleProps()}>{i18next.t("message")}</Button></p>}
            </div>
          </div>
        </div>
          <ProductTabs toggleDetails={this.toggleLang} />
        </div>

        {/* <div className="col-sm-3">
          <div className="card">
            {" "}
            <img
              src="/assets/images/abbott.png"
              alt="John"
              style={{ width: "100%" }}
            />
            <h1>{product.contact.name}</h1>
            <p className="cardTitle">{product.contact.title}</p>
            <p>{product.contact.organization}</p>
            <div style={{ margin: "24px 0" }}>
              <a href={product.contact.social.dribble}>
                <i className="fa fa-dribbble" />
              </a>
              <a href={product.contact.social.twitter}>
                <i className="fa fa-twitter" />
              </a>
              <a href={product.contact.social.linkedin}>
                <i className="fa fa-linkedin" />
              </a>
              <a href={product.contact.social.facebook}>
                <i className="fa fa-facebook" />
              </a>
            </div>
            <p>
              <button
                className="cardButton"
                data-toggle="modal"
                data-target="#myModal"
              >
                Contact
              </button>
            </p>
          </div>
        </div> */}
        <Modal
            isOpen={this.state.toggle}
            className="modal-content modal-lg">
            <ModalHeader toggle={()=>this.toggleProps()}>{i18next.t("sendusmessage")}</ModalHeader>
            <ModalBody>
            <Formik
                initialValues={{
                  message:this.state.message,
                  subject:this.state.subject
                }}
                validationSchema={Yup.object().shape({

                })}
                onSubmit={(data, actions) => {
                  let formData={"Text":this.state.message,
                  "Subject":this.state.subject,
                      "User_id_Sender":userService.getUserId(),
                      "User_id_Reciever":this.state.receiver,
                      "ProductID":this.props.product.id};

                  console.log(formData);
                  messagesService.createMessage(formData)
                  .then(
                    response => {
                      actions.setSubmitting(false);
                      this.setState({message:''});
                      this.setState({toggle:false});
                    },
                    error => {
                      actions.setSubmitting(false);
                      this.setState({toggle:false});
                      cogoToastHelper.error((error.toString()))
                    }
                  );
                }}
                render={({
                  values,
                  errors,
                  status,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <Form action="" method="post" className="messageForm1">
                    <FormGroup>
                      <Input
                                  type="input"
                                  id="subject"
                                  name="subject"
                                  placeholder={i18next.t("subject1")}
                                  onChange={(e) => this.onChangeSubject(`${e.target.value}`)}
                                  invalid={
                                    errors.subject && touched.subject
                                  }
                                  valid={this.state.subject}
                                  // this.state.product.description ||
                      />
                      <FormFeedback>{errors.subject}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Input
                                  type="textarea"
                                  id=""
                                  name="message"
                                  placeholder={i18next.t("entermessage")}
                                  onChange={(e) => this.onChange(`${e.target.value}`)}
                                  invalid={
                                    errors.message && touched.message
                                  }
                                  valid={this.state.message}
                                  // this.state.product.description ||
                      />
                      <FormFeedback>{errors.message}</FormFeedback>
                    </FormGroup>
                    {!isSubmitting && <Col sm="12" md="3" style={{padding:0}}>
                      <Button
                                className="cardButton"
                                block
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {i18next.t("send")}
                      </Button>
                    </Col>}
                    {isSubmitting && <Spinner color="success" />}
                  </Form>
                  )}
                />
            </ModalBody>
          </Modal>
      </div>
    </React.Fragment>
  );
  }
}

const mapStateToProps = state => ({
  product: state.product.product
});

export default connect(mapStateToProps)(ProductDetails);
