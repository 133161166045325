import { API } from "../urls";
import axios from "axios";

export const favouriteService = {
  getFavourites,
  setFavouriteComment
};

function getFavourites(){
  return axios.get(API.getFavourites.toUrl());
}

function setFavouriteComment(data){
  return axios.post(API.setFavouriteComment.toUrl(), data);
}