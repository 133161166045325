import {
  SEARCH_PRODUCTS,
  FETCH_PRODUCTS,
  LOADING,
  PAGINATE,
  PRODUCT_DETAILS,
  FETCH_ARTICLE,
  USER_PROFILE
} from "./types";
import axios from "axios";
import { results } from "./data/result";
import { store } from "../_helpers/store";
import { userService } from "../_services";
import { apiBaseUrl } from "../config.example";
import i18next from "i18next";

export function profile() {
  // console.log("Called from ", text);

  const token = userService.getToken();

  const api = `/account/profile`;

  axios
    .get(apiBaseUrl + api, { headers: { Authorization: `Bearer ${token}` } })

    .then(res => {
      // console.log("User Profile", api);
      try {
        store.dispatch({
          type: USER_PROFILE,
          payload: res
        });
      } catch (err) {
        console.log("error", err);
      }
    });
}
