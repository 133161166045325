import React from "react";
import { connect } from "react-redux";
import Rating from "./Rating";
import SearchBar from "../search/search-bar";
import Author from "./Author";
import Media from "./Media";
import Header from "./Header";
import Subscription from "./Subscription";
import ListGroup from "./ListGroup";

import { Markup } from "interweave";
import NavBar from "../navBar";

function Article(props) {
  const article = props.article;
  return (
    <React.Fragment>
      <NavBar></NavBar>
      <SearchBar />

      <div className="row">
        <div className="col-sm-2" />
        <div className="col-sm-8">
          <section className="post-content-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 post-title-block">
                  <Header />
                </div>

                <div className="col-lg-9 col-md-9 col-sm-12">
                  {<Markup content={article.content} />}
                </div>

                <div className="col-lg-3  col-md-3 col-sm-12">
                  <Subscription />
                  <Rating />
                  <Author />
                  <ListGroup />
                  <Media />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-sm-2"></div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  article: state.article.article
});

export default connect(mapStateToProps)(Article);
