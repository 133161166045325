import React from 'react';

export default function ListGroup(props) {

    return (
        <React.Fragment>
            <div className="list-group">
                <a className="list-group-item active" href="#">
                    <h4 className="list-group-item-heading">List group item heading</h4>
                    <p className="list-group-item-text">
                        Donec id elit non mi porta gravida at eget metus. Maecenas sed
                        diam eget risus varius blandit.
                  </p>
                </a>
                <a className="list-group-item" href="#">
                    <h4 className="list-group-item-heading">List group item heading</h4>
                    <p className="list-group-item-text">
                        Donec id elit non mi porta gravida at eget metus. Maecenas sed
                        diam eget risus varius blandit.
                  </p>
                </a>
                <a className="list-group-item" href="#">
                    <h4 className="list-group-item-heading">List group item heading</h4>
                    <p className="list-group-item-text">
                        Donec id elit non mi porta gravida at eget metus. Maecenas sed
                        diam eget risus varius blandit.
                  </p>
                </a>
            </div>
        </React.Fragment>
    );
}